/* eslint-disable unicorn/prefer-spread */
import { combineSlices, configureStore, createAction, type StoreEnhancer } from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import { combineEpics, createEpicMiddleware, type Epic } from 'redux-observable';
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

import { calendarSlice } from 'pages/calendar/_calendarSlice';
import { notificationEpic, notificationSlice } from 'store/notifications';
import { popoverSlice } from 'store/popoverSlice';
import { accessSlice } from './accessSlice';
import { api } from './api';
import { listViewSlice } from './pageViews';
import { preferencesSlice } from './preferences';
import { sidebarSlice } from './sidebar';

const reducer = combineSlices(
    api,
    preferencesSlice,
    listViewSlice,
    sidebarSlice,
    notificationSlice,
    popoverSlice,
    accessSlice,
    calendarSlice,
);

export type RootState = ReturnType<typeof reducer>;

export type AppEpic = Epic<unknown, unknown, RootState>;

export const stopNotifications = createAction('notifications/stop');

const rootEpic = combineEpics<unknown, unknown, RootState>(notificationEpic);

const epicMiddleware = createEpicMiddleware<unknown, unknown, RootState>();

const sentryReduxEnhancer = createReduxEnhancer() as StoreEnhancer;

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(api.middleware, epicMiddleware),
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),
});

epicMiddleware.run(rootEpic);

export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();

export const useAppSelector = useSelector.withTypes<RootState>();
