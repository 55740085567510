import { useContext, type Context } from 'react';
import { invariant } from './invariant';
import { isDefined } from './isDefined';

/**
 * Create a context consumer hook for contexts that are nullish by default
 * @param context - what you get by calling createContext
 * @param displayName - override the displayName for use in dev tools and the thrown error message
 * @returns the context value with the nullish type removed
 */
export const createSafeContextHook =
    <Value>(
        context: Context<Value | undefined>,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        displayName = context.displayName ?? 'Context',
    ) =>
    (): Value => {
        const value = useContext(context);

        invariant(isDefined(value), `${displayName} was used without a provider`);

        return value;
    };
