import { Trans } from '@lingui/macro';

import { Card } from 'components/Card/Card';
import { PageContainer } from 'components/Page/PageContainer';
import { PageContent } from 'components/Page/PageContent';
import { PageHeader } from 'components/Page/PageHeader';
import { PageTitle } from 'components/Page/PageTitle';

export const NotFound = () => {
    return (
        <PageContainer>
            <PageHeader>
                <PageTitle>
                    <Trans id="NotFound.Not_Found">Not Found</Trans>
                </PageTitle>
            </PageHeader>
            <PageContent>
                <Card>
                    <Trans id="NotFound.Could_not_find_the_requested_page.">Could not find the requested page.</Trans>
                </Card>
            </PageContent>
        </PageContainer>
    );
};
