import type { Ability } from 'types';

export const hasAbility = (abilitySets: Record<string, unknown>, ability: Ability) =>
    Object.values(abilitySets)
        .map((arr) => new Set(arr as string[]))
        .some(
            (abilities) =>
                !abilities.has(`!${ability}`) &&
                (abilities.has(ability) || abilities.has(ability.replace(/\..+/, '.*')) || abilities.has('*.*')),
        );
