import { Link } from 'components/Link/Link';
import { useTranslate } from 'hooks/useTranslate';
import { type ComponentProps } from 'react';
import ReactMarkdown from 'react-markdown';
import type { TranslateOptions } from 'utils/replaceWordsInTrans';

const components = {
    a: ({ href = '', color, ...props }: ComponentProps<'a'>) => <Link {...props} to={href} />,
    h1: ({ children }: ComponentProps<'h1'>) => <h1>{children}</h1>,
    h2: ({ children }: ComponentProps<'h2'>) => <h2>{children}</h2>,
    h3: ({ children }: ComponentProps<'h3'>) => <h3>{children}</h3>,
    h4: ({ children }: ComponentProps<'h4'>) => <h4>{children}</h4>,
    h5: ({ children }: ComponentProps<'h5'>) => <h5>{children}</h5>,
    h6: ({ children }: ComponentProps<'h6'>) => <h6>{children}</h6>,
    p: ({ children }: ComponentProps<'p'>) => <>{children}</>,
} as const;

export const Translate = ({ id, values }: TranslateOptions) => {
    const translate = useTranslate();

    return <ReactMarkdown components={components}>{translate({ id, values })}</ReactMarkdown>;
};
