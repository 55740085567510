export const PAGE_NUMBER_PARAM = 'page[number]';
export const PAGE_SIZE_PARAM = 'page[size]';
export const DEFAULT_PAGE_SIZE = '25';
export const SORT_PARAM = 'sort';
export const DEFAULT_SORT_COLUMN = '-created_at';
export const DEFAULT_SORT_SCHEDULE = 'sorting';
export const DEFAULT_PAGE_NUMBER = '1';

export const DEFAULT_PAGINATION_REQUEST = {
    [PAGE_NUMBER_PARAM]: DEFAULT_PAGE_NUMBER,
    [PAGE_SIZE_PARAM]: DEFAULT_PAGE_SIZE,
};
