import { type ReactNode } from 'react';
import { enhance } from 'utils/enhance';
import { cardHeaderWrapper, navCard, navCardContainer, navCardHeader, navCardText, navIcon } from './Navigation.css';
import { NavMenuListLink } from './NavMenuListLink';

type NavCardProps = {
    header: ReactNode;
    to: string;
    description: ReactNode;
    Icon?: (props: Record<string, unknown>) => ReactNode;
};

export const NavCard = enhance<'div', NavCardProps>('NavCard', ({ header, description, to, Icon }, ref) => {
    return (
        <div className={navCardContainer} ref={ref}>
            <NavMenuListLink to={to} className={navCard}>
                <div className={cardHeaderWrapper}>
                    {Icon && <Icon className={navIcon} />}
                    <h2 className={navCardHeader}>{header}</h2>
                </div>
                <p className={navCardText}>{description}</p>
            </NavMenuListLink>
        </div>
    );
});
