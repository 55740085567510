import { createSlice } from '@reduxjs/toolkit';
import { persistSlice } from './persistSlice';

export const listViewSlice = persistSlice(
    createSlice({
        name: 'listViews',
        initialState: {} as Record<string, boolean>,
        reducers: (create) => ({
            toggleListView: create.reducer<string>((state, { payload }) => {
                state[payload] = !state[payload];
            }),
        }),
    }),
);
