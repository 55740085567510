import { useLayoutEffect } from 'react';
import { useFocusVisible } from 'react-aria';

export const FocusManager = () => {
    const { isFocusVisible } = useFocusVisible({ isTextInput: true });

    useLayoutEffect(() => {
        if (isFocusVisible) {
            window.document.documentElement.classList.add('focus-visible');
        }

        return () => {
            window.document.documentElement.classList.remove('focus-visible');
        };
    }, [isFocusVisible]);

    return null;
};
