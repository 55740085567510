import { createSlice } from '@reduxjs/toolkit';
import { persistSlice } from './persistSlice';

const initialState = {
    isOpen: false,
};

export const sidebarSlice = persistSlice(
    createSlice({
        name: 'sidebar',
        initialState,
        reducers: (create) => ({
            toggle: create.reducer((state) => {
                state.isOpen = !state.isOpen;
            }),
        }),
        selectors: {
            isOpen: (sliceState) => sliceState.isOpen,
        },
    }),
);
