import clsx from 'clsx';
import { Button as AriaButton } from 'react-aria-components';

import { enhance } from 'utils/enhance';
import { button, type ButtonVariants } from './button.css';

export const Button = enhance<typeof AriaButton, ButtonVariants>(
    'Button',
    ({ size, variant, color, shape, className, ...props }, ref) => (
        <AriaButton {...props} className={clsx(button({ size, variant, color, shape }), className)} ref={ref} />
    ),
);
