import clsx from 'clsx';

import { m } from 'framer-motion';
import { enhance } from 'utils/enhance';
import { column, type ColumnVariants } from './Column.css';

export const Column = enhance<'div', ColumnVariants>(
    'Column',
    ({ gap, alignItems, justifyContent, width, height, rowGap, children, className, ...props }, ref) => (
        <div
            {...props}
            className={clsx(column({ alignItems, justifyContent, rowGap, gap, width, height }), className)}
            ref={ref}
        >
            {children}
        </div>
    ),
);

export const MotionColumn = m(Column);
