import { Provider } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { ToastManager } from 'components/Notifications/ToastManager';
import { LanguageManager } from 'i18n/LanguageManager';
import { SearchParamsProvider } from 'searchParams/SearchParamsProvider';
import { persistor, store } from 'store/store';
import { FocusManager } from 'theme/FocusManager';
import 'theme/global.css';
import { MotionManager } from 'theme/MotionManager';
import { ThemeManager } from 'theme/themeManager';

export const Root = () => {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <LanguageManager>
                    <SearchParamsProvider>
                        <MotionManager>
                            <Outlet />
                            <FocusManager />
                            <ToastManager />
                        </MotionManager>
                    </SearchParamsProvider>
                </LanguageManager>
                <ThemeManager />
            </PersistGate>
        </Provider>
    );
};
