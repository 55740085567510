import clsx from 'clsx';
import { enhance } from 'utils/enhance';
import { cardInset, type CardInsetVariants } from './CardInset.css';

export const CardInset = enhance<'div', CardInsetVariants>(
    'CardInset',
    ({ children, position, color, className, ...props }, ref) => {
        return (
            <div {...props} className={clsx(cardInset({ position, color }), className)} ref={ref}>
                {children}
            </div>
        );
    },
);
