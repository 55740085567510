export const toHashMap = <In, Out = In, Key extends string | number | symbol = string>(
    keySelector: (item: In, index: number, arr: Iterable<In>) => Key,
    valueSelector: (item: In, index: number, arr: Iterable<In>, value?: Out) => Out,
    data: Iterable<In>,
) =>
    [...data].reduce<Partial<Record<Key, Out>>>((acc, cur, idx, arr) => {
        const key = keySelector(cur, idx, arr);
        const value = valueSelector(cur, idx, arr, acc[key]);
        acc[key] = value;
        return acc;
    }, {}) as Record<Key, Out>;
