import { getLocalTimeZone } from '@internationalized/date';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { detectLocale } from 'i18n/activateLocale';
import type { UUID } from 'types';
import { accessSlice } from './accessSlice';
import { type RootState } from './store';

const getFromLocalStorage = (): null | UUID => {
    const data = window.localStorage.getItem('persist:access');

    if (data === null) {
        return null;
    }

    return JSON.parse((JSON.parse(data) as { accessId: string }).accessId) as UUID;
};

export const BASE_URL = `api/v1/`;

const baseBaseQuery = fetchBaseQuery({
    baseUrl: `/api/`,
    headers: {
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
    },
    credentials: 'include',
    prepareHeaders: (headers, baseQueryApi) => {
        const accessId =
            accessSlice.selectors.selectAccess(baseQueryApi.getState() as RootState) ?? getFromLocalStorage();

        if (accessId != null) {
            headers.set('X-CONTEXT', accessId);
        }

        headers.set(
            // eslint-disable-next-line lingui/no-unlocalized-strings
            'Accept-Language',
            detectLocale(),
        );

        headers.set('X-TIMEZONE', getLocalTimeZone());
    },
});

export const baseQuery: typeof baseBaseQuery = async (args, api, extraOptions) => {
    const result = await baseBaseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        window.localStorage.setItem('redirectPath', window.location.pathname);
        window.localStorage.setItem('redirectSearch', window.location.search);

        window.location.replace('/login');
    }

    return result;
};
