import { captureException } from '@sentry/react';
import { Fragment } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import { Card } from 'components/Card/Card';
import { PageContainer } from 'components/Page/PageContainer';
import { PageContent } from 'components/Page/PageContent';
import { PageHeader } from 'components/Page/PageHeader';
import { PageTitle } from 'components/Page/PageTitle';
import type { ServerErrors } from 'store/types';

export const ErrorPage = () => {
    const error = useRouteError() as ServerErrors | Error | { status: string; error: string };

    if (isRouteErrorResponse(error)) {
        captureException(error.data, { level: 'fatal' });

        return (
            <PageContainer>
                <PageHeader>
                    <PageTitle>
                        {error.status} - {error.statusText}
                    </PageTitle>
                </PageHeader>
                <PageContent>
                    <Card>{error.data}</Card>
                </PageContent>
            </PageContainer>
        );
    }

    if (error instanceof Error) {
        captureException(error, { level: 'fatal' });

        return (
            <PageContainer>
                <PageHeader>
                    <PageTitle>{error.name}</PageTitle>
                </PageHeader>
                <PageContent>
                    <Card>{error.message}</Card>
                </PageContent>
            </PageContainer>
        );
    }

    if ('error' in error) {
        captureException(error.error, { level: 'fatal' });

        return (
            <PageContainer>
                <PageHeader>
                    <PageTitle>
                        {error.status} - {error.error}
                    </PageTitle>
                </PageHeader>
                <PageContent>
                    <Card>
                        {error.status} - {error.error}
                    </Card>
                </PageContent>
            </PageContainer>
        );
    }

    if ('data' in error && error.data != null) {
        for (const err of error.data.errors) {
            captureException('detail' in err ? err.detail : err.title, { level: 'fatal' });
        }

        return (
            <Fragment>
                {error.data.errors.map((err, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Fragment key={idx}>
                        <PageContainer>
                            <PageHeader>
                                <PageTitle>
                                    {err.status} - {err.title}
                                </PageTitle>
                            </PageHeader>
                            {'detail' in err && (
                                <PageContent>
                                    <Card>{err.detail}</Card>
                                </PageContent>
                            )}
                        </PageContainer>
                    </Fragment>
                ))}
            </Fragment>
        );
    }

    captureException(error, { level: 'fatal' });

    return (
        <PageContainer>
            <PageHeader>
                {/* eslint-disable-next-line lingui/no-unlocalized-strings */}
                <PageTitle>Something went wrong!</PageTitle>
            </PageHeader>
            <PageContent>
                <Card>
                    <pre>{JSON.stringify(error, null, 2)}</pre>
                </Card>
            </PageContent>
        </PageContainer>
    );
};
