import { accountingFormat, type AccountingMethod } from 'utils/financeFormat';
import { isDefined } from 'utils/isDefined';
import type {
    AnyEndpointBuilder,
    ApiRecord,
    ApiResponse,
    ApiResponseRecord,
    ApiResponseRecordList,
    Included,
    MassActionRequest,
    MassActionResult,
    PaginatedResult,
    Relationships,
} from './types';

export const flattributes = <Out>({ id, attributes }: ApiRecord) =>
    ({
        id,
        ...attributes,
    }) as Out;

export const transformFields = ({ fields }: { fields?: Record<string, string[]> }) => ({
    ...Object.entries(fields ?? {})
        .map(([key, values]) => ({
            [`fields[${key}]`]: values.join(','),
        }))
        .reduce<Record<string, string>>(
            (prev, current) => ({
                ...prev,
                ...current,
            }),
            {},
        ),
});

export const getAll = <Entity extends { id: string }>(
    builder: AnyEndpointBuilder,
    type: string,
    params?: Record<string, string>,
) =>
    builder.query<
        Entity[],
        | (Record<string, unknown> & {
              fields?: ReadonlyArray<keyof Entity>;
              search?: Record<string, string | undefined>;
              filters?: Record<string, string | undefined>;
          })
        | void
    >({
        query: ({ fields, search, filters, ...rest } = {}) => ({
            url: `/v1/${type}`,
            params: {
                ...rest,
                ...params,
                ...search,
                ...filters,
                [`fields[${type}]`]: fields?.join(','),
            },
        }),
        providesTags: (result = []) => [...result.map(({ id }) => ({ type, id })), { type, id: 'LIST' }],
        transformResponse: <Out>(response: ApiResponse): Out[] => response.data.map(flattributes<Out>),
    });

export const getMany = <Entity extends { id: string }>(
    builder: AnyEndpointBuilder,
    type: string,
    columns?: ReadonlyArray<keyof Entity> | null,
    defaults?: Record<string, string>,
) =>
    builder.query<
        PaginatedResult<Entity>,
        { search: Record<string, string>; fields?: ReadonlyArray<keyof Entity> | null }
    >({
        query: ({ search, fields }) => ({
            url: `/v1/${type}`,
            params: {
                ...defaults,
                ...search,
                [`fields[${type}]`]: columns?.join(',') ?? fields,
            },
        }),
        providesTags: (result) => [...(result?.data.map(({ id }) => ({ type, id })) ?? []), { type, id: 'LIST' }],
        transformResponse: <Out>(response: ApiResponse): PaginatedResult<Out> => ({
            data: response.data.map(flattributes<Out>),
            page: response.meta?.page,
        }),
    });

export const getManyRelated = <Entity extends { id: string }>(
    builder: AnyEndpointBuilder,
    type: string,
    related: string,
) =>
    builder.query<
        Entity[],
        {
            path: { id: string };
        }
    >({
        query: ({ path: { id } }) => ({
            url: `/v1/${type}/${id}/relationships/${related}`,
        }),
        providesTags: (_result, _error, { path: { id } }) => [{ type, id }],
        transformResponse: (response: { data: ApiRecord }) => flattributes(response.data ?? []),
    });

export const getOne = <Entity extends { id: string }>(
    builder: AnyEndpointBuilder,
    type: string,
    args?: Record<string, string>,
    columns?: ReadonlyArray<keyof Entity>,
) =>
    builder.query<
        Entity,
        {
            path: { id: string };
            id?: string;
        }
    >({
        query: ({ path, id: paramId }) => ({
            url: `/v1/${type}/${path?.id ?? paramId}`,
            params: {
                ...args,
                [`fields[${type}]`]: columns?.join(','),
            },
        }),
        providesTags: (_result, _error, { path, id: paramId }) => [{ type, id: path?.id ?? paramId }],
        transformResponse: (response: { data: ApiRecord }) => flattributes(response.data),
    });

export const create = <Entity>(builder: AnyEndpointBuilder, type: string) =>
    builder.mutation<Entity, Entity>({
        query: ({ ...attributes }) => ({
            url: `/v1/${type}`,
            method: 'POST',
            body: {
                data: {
                    type,
                    attributes,
                },
            },
        }),
        invalidatesTags: [{ type, id: 'LIST' }],
    });

export const updateWihPayload = <Entity extends { id: string }>(builder: AnyEndpointBuilder, type: string) =>
    builder.mutation<Entity, Entity>({
        query: ({ id, ...attributes }) => ({
            url: `/v1/${type}/${id}`,
            method: 'PATCH',
            body: attributes,
        }),
        invalidatesTags: (_result, _error, { id }) => [{ type, id }],
    });

export const update = <Entity extends { id: string }>(builder: AnyEndpointBuilder, type: string) =>
    builder.mutation<Entity, Entity>({
        query: ({ id, ...attributes }) => ({
            url: `/v1/${type}/${id}`,
            method: 'PATCH',
            body: {
                data: {
                    type,
                    id,
                    attributes,
                },
            },
        }),
        invalidatesTags: (_result, _error, { id }) => [{ type, id }],
    });

export const remove = (builder: AnyEndpointBuilder, type: string) =>
    builder.mutation<void, string>({
        query: (id) => ({
            url: `/v1/${type}/${id}`,
            method: 'DELETE',
        }),
        invalidatesTags: [{ type, id: 'LIST' }],
    });

export const massAction = (builder: AnyEndpointBuilder, type: string, action: string) =>
    builder.mutation<MassActionResult, MassActionRequest>({
        query: ({ selected }) => ({
            url: `/v1/${type}/-actions/${action}`,
            method: 'POST',
            body: {
                selected,
            },
        }),
        invalidatesTags: (_res, _error, { selected = [] }) => [
            { type, id: 'LIST' },
            ...selected.map((id) => ({ type, id })),
        ],
    });

export const formatIncluded = (included: ApiRecord[]) =>
    included.reduce<Included>((acc, cur) => {
        (acc[cur.type] ??= {})[cur.id] = {
            id: cur.id,
            ...cur.attributes,
        } as Included[keyof Included][string];
        return acc;
    }, {});

export const mergeData = <Item extends Record<string, unknown>>(
    relationships: Relationships,
    included: Included,
): Record<string, unknown> =>
    Object.entries(relationships ?? []).reduce((acc, [fieldName, { data }]) => {
        if (isDefined(data)) {
            if (Array.isArray(data)) {
                // @ts-expect-error fix me
                acc[fieldName as keyof Item] = data.map((association) => included[association.type][association.id]!);
            } else {
                // @ts-expect-error fix me
                acc[fieldName as keyof Item] = included[data.type][data.id]!;
            }
        }
        return acc;
    }, {});

export const getOneWithRelationships = <Out extends Record<string, unknown>>(
    builder: AnyEndpointBuilder,
    type: string,
    params: Record<string, string>,
) =>
    builder.query<Out, { id: string }>({
        query: ({ id }) => ({
            url: `/v1/${type}/${id}`,
            params,
        }),
        providesTags: (_result, _error, { id }) => [{ type, id }],
        transformResponse: ({ included, data: { id, attributes, relationships } }: ApiResponseRecord): Out =>
            ({
                id,
                ...attributes,
                ...(isDefined(included) ? mergeData(relationships, formatIncluded(included)) : {}),
            }) as Record<string, unknown> as Out,
    });

export const getManyWithRelationships = <Entity extends { id: string }>(
    builder: AnyEndpointBuilder,
    type: string,
    params: Record<string, string>,
    columns?: ReadonlyArray<keyof Entity>,
) =>
    builder.query<Entity[], { search?: Record<string, string> }>({
        query: ({ search }) => ({
            url: `/v1/${type}`,
            params: {
                ...params,
                ...search,
                [`fields[${type}]`]: columns?.join(','),
            },
        }),
        providesTags: (result = []) => [...result.map(({ id }) => ({ type, id })), { type, id: 'LIST' }],
        transformResponse: <Out>({ included, data }: ApiResponseRecordList): Out[] =>
            data.map(
                ({ id, attributes, relationships }) =>
                    ({
                        id,
                        ...attributes,
                        ...(isDefined(included) ? mergeData(relationships, formatIncluded(included)) : {}),
                    }) as Record<string, unknown> as Out,
            ),
    });

export const getManyWithPageRelationshipsPaginated = <Entity extends { id: string }>(
    builder: AnyEndpointBuilder,
    type: string,
    params: Record<string, string>,
    columns?: ReadonlyArray<keyof Entity>,
) =>
    builder.query<PaginatedResult<Entity>, { search?: Record<string, string> }>({
        query: ({ search }) => ({
            url: `/v1/${type}`,
            params: {
                ...params,
                ...search,
                [`fields[${type}]`]: columns?.join(','),
            },
        }),
        providesTags: (result) => [...(result?.data.map(({ id }) => ({ type, id })) ?? []), { type, id: 'LIST' }],
        transformResponse: <Out>({ included, data, meta }: ApiResponseRecordList): PaginatedResult<Out> => ({
            data: data.map(
                ({ id, attributes, relationships }) =>
                    ({
                        id,
                        ...attributes,
                        ...(isDefined(included) ? mergeData(relationships, formatIncluded(included)) : {}),
                    }) as Record<string, unknown> as Out,
            ),
            page: meta?.page,
        }),
    });

const responseHandler = async (response: Response) => window.URL.createObjectURL(await response.blob());

export const exportManyTo = (format: 'csv' | 'pdf' = 'csv', builder: AnyEndpointBuilder, type: string) =>
    builder.query<string, Record<string, string>>({
        query: ({ 'page[number]': _deleted, 'page[size]': _alsoDeleted, ...params }) => ({
            url: `/v1/${type}/-actions/export-${format}`,
            params,
            headers: {
                Accept: `application/${format}`,
            },
            responseHandler,
        }),
    });

export const exportOneTo = (format: 'csv' | 'pdf' = 'csv', builder: AnyEndpointBuilder, type: string) =>
    builder.query<string, { id: string } & Record<string, string>>({
        query: ({ 'page[number]': _deleted, 'page[size]': _alsoDeleted, id }) => ({
            url: `/v1/${type}/${id}/-actions/export-${format}`,
            headers: {
                Accept: `application/${format}`,
            },
            responseHandler,
        }),
    });

export const exportOneToAccounting = (method: AccountingMethod, builder: AnyEndpointBuilder, type: string) =>
    builder.query<string, Record<string, string>>({
        query: ({ 'page[number]': _deleted, 'page[size]': _alsoDeleted, id }) => ({
            url: `/v1/${type}/${id}/-actions/export-accounting-${method}`,
            headers: {
                Accept: accountingFormat[method],
            },
            responseHandler,
        }),
    });

export const exportManyToCustomCsv = (builder: AnyEndpointBuilder, type: string) =>
    builder.query<string, { id: string } & Record<string, string>>({
        query: ({ 'page[number]': _deleted, 'page[size]': _alsoDeleted, id }) => ({
            url: `/v1/${type}/${id}/-actions/export-financials`,
            responseHandler,
        }),
    });
