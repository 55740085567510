import type { Slice, SliceCaseReducers } from '@reduxjs/toolkit';
import type { SliceSelectors } from '@reduxjs/toolkit/dist/createSlice';
import { persistReducer, type PersistConfig } from 'redux-persist';
import storage from 'redux-persist/es/storage';

export const persistSlice = <
    State,
    CaseReducers extends SliceCaseReducers<State> = SliceCaseReducers<State>,
    Name extends string = string,
    ReducerPath extends string = Name,
    Selectors extends SliceSelectors<State> = SliceSelectors<State>,
>(
    slice: Slice<State, CaseReducers, Name, ReducerPath, Selectors>,
    config?: Omit<PersistConfig<State>, 'storage' | 'key'>,
): Slice<State, CaseReducers, Name, ReducerPath, Selectors> => ({
    ...slice,
    reducer: persistReducer(
        {
            ...config,
            storage,
            key: slice.reducerPath,
        },
        slice.reducer,
    ) as Slice<State, CaseReducers, Name, ReducerPath, Selectors>['reducer'],
});
