import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { Navigation } from 'components/Navigation/Navigation';
import { HistoryProvider } from 'history/HistoryProvider';
import { PermissionProvider } from 'permission/PermissionProvider';
import { createGetNotificationsThunk } from 'store/notifications';
import { useAppDispatch } from 'store/store';

export const WithNavigation = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        void dispatch(createGetNotificationsThunk());
    }, [dispatch]);

    return (
        <PermissionProvider>
            <HistoryProvider>
                <Navigation />
                <Outlet />
            </HistoryProvider>
        </PermissionProvider>
    );
};
