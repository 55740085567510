import { useEventHandler } from 'hooks/useEventHandler';
import { useMemo, useRef, type ReactNode } from 'react';
import { api } from 'store/api';

import { accessSlice } from 'store/accessSlice';
import { useAppSelector } from 'store/store';
import { type Ability, type ProfileID, type Role, type TempUserDataAccess, type User } from 'types';
import { invariant } from 'utils/invariant';
import { isDefined } from 'utils/isDefined';
import { makeOptions } from 'utils/makeOptions';
import type { Prettify } from 'utils/prettify';
import { toHashMap } from 'utils/toHashMap';
import { hasAbility } from './hasAbility';
import { PermissionContext } from './PermissionContext';

export const PermissionProvider = ({ children }: { children: ReactNode }) => {
    const { data: user, error } = api.useGetMeQuery();
    const accessID = useAppSelector(accessSlice.selectors.selectAccess);

    if (error) window.location.href = '/login';

    invariant(isDefined(user), 'User data unavailable');

    const permissionCache = useRef<Record<string, boolean>>({});

    const userAccessList = useMemo(() => Object.entries(user.access ?? {}), [user.access]);

    const singularTenantAccessList = Object.entries(user.access)
        .filter(([_, access]) => access.access_singular_tenant)
        .map(([_, access]) => access);

    const multipleTenantAccessList = Object.entries(user.access)
        .filter(([_, access]) => access.access_multiple_tenants)
        .map(([_, access]) => access);

    const noAccount = useMemo(
        () =>
            multipleTenantAccessList.find((access) => access.id === user.context?.id) ?? multipleTenantAccessList?.[0],
        [multipleTenantAccessList, user.context?.id],
    ) as Prettify<User['user_data']['access'][number]>;

    const accountsList = useMemo(
        () => [...singularTenantAccessList, noAccount],
        [noAccount, singularTenantAccessList],
    ).filter(Boolean) as Prettify<TempUserDataAccess>;

    const activeAccess = useMemo(
        () => userAccessList.find(([_, access]) => access.id === accessID)?.[1],
        [accessID, userAccessList],
    );

    const accessOptions = makeOptions(
        toHashMap(
            (key) => key.association_item_id,
            (val) => val.item_name,
            [...multipleTenantAccessList],
        ),
    ).filter(Boolean);

    invariant(isDefined(activeAccess), 'No access available');

    const checkPermission = useEventHandler((ability: Ability | Ability[]): boolean => {
        if (Array.isArray(ability)) {
            return ability.some(checkPermission);
        }

        return (permissionCache.current[`${activeAccess.id}_${ability}`] ??= hasAbility(
            activeAccess.abilities,
            ability,
        ));
    });

    const checkRole = useEventHandler((role: Role | Role[]): boolean => {
        if (Array.isArray(role)) {
            return role.some(checkRole);
        }

        return role.startsWith('!') ? activeAccess.role !== role.slice(1) : activeAccess.role === role;
    });

    const checkProfile = useEventHandler((profile_id: ProfileID | ProfileID[]): boolean => {
        if (Array.isArray(profile_id)) {
            return profile_id.some(checkProfile);
        }

        return profile_id.startsWith('!')
            ? Object.entries(user.access).every(([_, access]) => access.association_item_id !== profile_id.slice(1))
            : Object.entries(user.access).some(([_, access]) => access.association_item_id === profile_id);
    });

    const value = useMemo(
        () => ({
            user,
            activeAccess,
            accessOptions,
            accountsList,
            singularTenantAccessList,
            multipleTenantAccessList,
            checkPermission,
            checkProfile,
            checkRole,
        }),
        [
            user,
            activeAccess,
            accessOptions,
            accountsList,
            singularTenantAccessList,
            multipleTenantAccessList,
            checkPermission,
            checkProfile,
            checkRole,
        ],
    );

    return <PermissionContext.Provider value={value}>{children}</PermissionContext.Provider>;
};
