export const UserAccessPermission = {
DEFAULT: "DEFAULT",
ADMINISTRATOR_DEFAULT: "ADMINISTRATOR_DEFAULT",
ADMINISTRATOR_FINANCES: "ADMINISTRATOR_FINANCES",
ADMINISTRATOR_INVOICES: "ADMINISTRATOR_INVOICES",
ADMINISTRATOR_PROFILES: "ADMINISTRATOR_PROFILES",
ADMINISTRATOR_SCHEDULES: "ADMINISTRATOR_SCHEDULES",
ADMINISTRATOR_SETTINGS: "ADMINISTRATOR_SETTINGS",
ADMINISTRATOR_SHIFTS: "ADMINISTRATOR_SHIFTS",
ADMINISTRATOR_USERS: "ADMINISTRATOR_USERS",
PROFILE_DEFAULT: "PROFILE_DEFAULT",
} as const;
export type UserAccessPermissions = typeof UserAccessPermission[keyof typeof UserAccessPermission];

export const UserAccessRole = {
PROFILE: "PROFILE",
ADMINISTRATOR_DEFAULT: "ADMINISTRATOR_DEFAULT",
ADMINISTRATOR_FINANCE: "ADMINISTRATOR_FINANCE",
ADMINISTRATOR_MANAGER: "ADMINISTRATOR_MANAGER",
ADMINISTRATOR_PLANNER: "ADMINISTRATOR_PLANNER",
} as const;
export type UserAccessRoles = typeof UserAccessRole[keyof typeof UserAccessRole];

export const AbsenceMutationType = {
BUILDUP: "BUILDUP",
CONVERSION: "CONVERSION",
EXPIRATION: "EXPIRATION",
MANUAL: "MANUAL",
REGISTRATION: "REGISTRATION",
RESET: "RESET",
} as const;
export type AbsenceMutationTypes = typeof AbsenceMutationType[keyof typeof AbsenceMutationType];

export const AbsenceReason = {
PERSONAL: "PERSONAL",
MEDICAL: "MEDICAL",
PREFERENCE: "PREFERENCE",
SPECIAL: "SPECIAL",
} as const;
export type AbsenceReasons = typeof AbsenceReason[keyof typeof AbsenceReason];

export const AbsenceStatus = {
REQUESTED: "REQUESTED",
PENDING: "PENDING",
APPROVED: "APPROVED",
DENIED: "DENIED",
REJECTED: "REJECTED",
} as const;
export type AbsenceStatuses = typeof AbsenceStatus[keyof typeof AbsenceStatus];

export const AbsenceTypeInterval = {
YEARS: "YEARS",
MONTHS: "MONTHS",
WEEKS: "WEEKS",
FOREVER: "FOREVER",
} as const;
export type AbsenceTypeIntervals = typeof AbsenceTypeInterval[keyof typeof AbsenceTypeInterval];

export const AbsenceTypeMechanism = {
CALENDAR: "CALENDAR",
LINEAR: "LINEAR",
} as const;
export type AbsenceTypeMechanisms = typeof AbsenceTypeMechanism[keyof typeof AbsenceTypeMechanism];

export const ActivityAction = {
CREATE: "CREATE",
UPDATE: "UPDATE",
DELETE: "DELETE",
RESTORE: "RESTORE",
SHIFT_ASSIGN_PROFILE: "SHIFT_ASSIGN_PROFILE",
SHIFT_ASSIGN_EXTRA: "SHIFT_ASSIGN_EXTRA",
SHIFT_UNASSIGN_PROFILE: "SHIFT_UNASSIGN_PROFILE",
SHIFT_UNASSIGN_EXTRA: "SHIFT_UNASSIGN_EXTRA",
SHIFT_MARKET_ADD: "SHIFT_MARKET_ADD",
SHIFT_MARKET_REMOVE: "SHIFT_MARKET_REMOVE",
UPLOAD: "UPLOAD",
} as const;
export type ActivityActions = typeof ActivityAction[keyof typeof ActivityAction];

export const AssignmentMethod = {
ASSIGNED: "ASSIGNED",
MARKET_ASSIGN: "MARKET_ASSIGN",
MARKET_PUSH: "MARKET_PUSH",
MARKET_SWAP: "MARKET_SWAP",
MARKET_TRADE: "MARKET_TRADE",
SCHEDULED: "SCHEDULED",
} as const;
export type AssignmentMethods = typeof AssignmentMethod[keyof typeof AssignmentMethod];

export const DeclarationCostType = {
KILOMETERS: "KILOMETERS",
PARKING: "PARKING",
MISCELLANEOUS: "MISCELLANEOUS",
} as const;
export type DeclarationCostTypes = typeof DeclarationCostType[keyof typeof DeclarationCostType];

export const HoursInterval = {
MONTH: "MONTH",
WEEK: "WEEK",
} as const;
export type HoursIntervals = typeof HoursInterval[keyof typeof HoursInterval];

export const InvoiceLineType = {
DECLARATION_MISCELLANEOUS: "DECLARATION_MISCELLANEOUS",
DECLARATION_KILOMETERS: "DECLARATION_KILOMETERS",
MISCELLANEOUS: "MISCELLANEOUS",
SHIFT_HOURS: "SHIFT_HOURS",
SHIFT_META: "SHIFT_META",
} as const;
export type InvoiceLineTypes = typeof InvoiceLineType[keyof typeof InvoiceLineType];

export const NotificationSeverity = {
ERROR: "ERROR",
SUCCESS: "SUCCESS",
NEUTRAL: "NEUTRAL",
} as const;
export type NotificationSeverities = typeof NotificationSeverity[keyof typeof NotificationSeverity];

export const ProfileType = {
INDIVIDUAL: "INDIVIDUAL",
BUSINESS: "BUSINESS",
GROUP: "GROUP",
} as const;
export type ProfileTypes = typeof ProfileType[keyof typeof ProfileType];

export const ScheduleResultOutputTitle = {
DIMENSION_LOCATION: "DIMENSION_LOCATION",
DIMENSION_PERIOD: "DIMENSION_PERIOD",
DIMENSION_PROFESSION: "DIMENSION_PROFESSION",
DIMENSION_SKILL: "DIMENSION_SKILL",
DIMENSION_TIMESLOT: "DIMENSION_TIMESLOT",
SUPPLY_LOCATION: "SUPPLY_LOCATION",
SUPPLY_PERIOD: "SUPPLY_PERIOD",
SUPPLY_PROFESSION: "SUPPLY_PROFESSION",
SUPPLY_SKILL: "SUPPLY_SKILL",
SUPPLY_TIMESLOT: "SUPPLY_TIMESLOT",
} as const;
export type ScheduleResultOutputTitles = typeof ScheduleResultOutputTitle[keyof typeof ScheduleResultOutputTitle];

export const ScheduleResultStatus = {
CONCEPT: "CONCEPT",
PENDING: "PENDING",
RUNNING: "RUNNING",
FINISHED: "FINISHED",
EXCEPTION: "EXCEPTION",
SELECTED: "SELECTED",
} as const;
export type ScheduleResultStatuses = typeof ScheduleResultStatus[keyof typeof ScheduleResultStatus];

export const ScheduleStatus = {
CONCEPT: "CONCEPT",
PREPARED: "PREPARED",
RUNNING: "RUNNING",
PUBLISHED: "PUBLISHED",
} as const;
export type ScheduleStatuses = typeof ScheduleStatus[keyof typeof ScheduleStatus];

export const ScheduleStepStatus = {
CONCEPT: "CONCEPT",
ACTIVE: "ACTIVE",
FINISHED: "FINISHED",
COMPLETED: "COMPLETED",
} as const;
export type ScheduleStepStatuses = typeof ScheduleStepStatus[keyof typeof ScheduleStepStatus];

export const ScheduleStepType = {
AUTOMATIC_BALANCED: "AUTOMATIC_BALANCED",
AUTOMATIC_RANDOM: "AUTOMATIC_RANDOM",
OPTING_ASSIGNED: "OPTING_ASSIGNED",
OPTING_BALANCED: "OPTING_BALANCED",
OPTING_CANCELED: "OPTING_CANCELED",
} as const;
export type ScheduleStepTypes = typeof ScheduleStepType[keyof typeof ScheduleStepType];

export const SchedulingAssignmentStatus = {
DIMENSIONAL_SUPPLY: "DIMENSIONAL_SUPPLY",
INTERVAL_LIMIT: "INTERVAL_LIMIT",
MAXIMUM_SUPPLY: "MAXIMUM_SUPPLY",
SHIFT_ASSIGNED: "SHIFT_ASSIGNED",
SHIFT_BLOCKED: "SHIFT_BLOCKED",
SHIFT_BLOCKED_ABSENCE: "SHIFT_BLOCKED_ABSENCE",
SHIFT_BLOCKED_ASSIGNED_SHIFT: "SHIFT_BLOCKED_ASSIGNED_SHIFT",
SHIFT_BLOCKED_EXISTING_SHIFT: "SHIFT_BLOCKED_EXISTING_SHIFT",
SHIFT_BLOCKED_INACTIVE_ASSIGNEE: "SHIFT_BLOCKED_INACTIVE_ASSIGNEE",
SHIFT_COMPATIBLE: "SHIFT_COMPATIBLE",
SHIFT_COMPATIBLE_LOCATION: "SHIFT_COMPATIBLE_LOCATION",
SHIFT_COMPATIBLE_PROFESSION: "SHIFT_COMPATIBLE_PROFESSION",
SHIFT_COMPATIBLE_SKILL: "SHIFT_COMPATIBLE_SKILL",
SHIFT_COMPATIBLE_TIMESLOT: "SHIFT_COMPATIBLE_TIMESLOT",
SHIFT_NOT_ASSIGNABLE: "SHIFT_NOT_ASSIGNABLE",
MATRIX_LIMIT: "MATRIX_LIMIT",
} as const;
export type SchedulingAssignmentStatuses = typeof SchedulingAssignmentStatus[keyof typeof SchedulingAssignmentStatus];

export const SchedulingBlockStatus = {
BLOCKING: "BLOCKING",
PREFERENCE: "PREFERENCE",
} as const;
export type SchedulingBlockStatuses = typeof SchedulingBlockStatus[keyof typeof SchedulingBlockStatus];

export const SchedulingBlockType = {
ABSENCE: "ABSENCE",
ASSIGNED_SHIFT: "ASSIGNED_SHIFT",
EXISTING_SHIFT: "EXISTING_SHIFT",
INACTIVE_ASSIGNEE: "INACTIVE_ASSIGNEE",
} as const;
export type SchedulingBlockTypes = typeof SchedulingBlockType[keyof typeof SchedulingBlockType];

export const SchedulingCompatibilityFail = {
LOCATION: "LOCATION",
PROFESSION: "PROFESSION",
TIMESLOT: "TIMESLOT",
SKILL: "SKILL",
} as const;
export type SchedulingCompatibilityFails = typeof SchedulingCompatibilityFail[keyof typeof SchedulingCompatibilityFail];

export const SchedulingIndexInterval = {
WEEK: "WEEK",
MONTH: "MONTH",
SCHEDULE: "SCHEDULE",
} as const;
export type SchedulingIndexIntervals = typeof SchedulingIndexInterval[keyof typeof SchedulingIndexInterval];

export const SchedulingMethod = {
REASSIGN: "REASSIGN",
AUTOMATIC_BALANCED: "AUTOMATIC_BALANCED",
AUTOMATIC_RANDOM: "AUTOMATIC_RANDOM",
OPTING_ASSIGNED: "OPTING_ASSIGNED",
OPTING_BALANCED: "OPTING_BALANCED",
OPTING_CANCELED: "OPTING_CANCELED",
} as const;
export type SchedulingMethods = typeof SchedulingMethod[keyof typeof SchedulingMethod];

export const SchedulingMethodType = {
AUTOMATIC: "AUTOMATIC",
CANCEL: "CANCEL",
OPTING: "OPTING",
ASSIGN: "ASSIGN",
} as const;
export type SchedulingMethodTypes = typeof SchedulingMethodType[keyof typeof SchedulingMethodType];

export const SchedulingPeriodInterval = {
MONTH: "MONTH",
WEEK: "WEEK",
} as const;
export type SchedulingPeriodIntervals = typeof SchedulingPeriodInterval[keyof typeof SchedulingPeriodInterval];

export const ShiftMarketType = {
ASSIGN: "ASSIGN",
PUSH: "PUSH",
SWAP: "SWAP",
TRADE: "TRADE",
} as const;
export type ShiftMarketTypes = typeof ShiftMarketType[keyof typeof ShiftMarketType];

export const Math = {
PLUS: "PLUS",
MINUS: "MINUS",
} as const;
export type Maths = typeof Math[keyof typeof Math];

export const Parity = {
EVEN: "EVEN",
ODD: "ODD",
} as const;
export type Parities = typeof Parity[keyof typeof Parity];

export const Weekday = {
MON: "MON",
TUE: "TUE",
WED: "WED",
THU: "THU",
FRI: "FRI",
SAT: "SAT",
SUN: "SUN",
} as const;
export type Weekdays = typeof Weekday[keyof typeof Weekday];
