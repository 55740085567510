import { t, Trans } from '@lingui/macro';
import { AnimatePresence } from 'framer-motion';
import { useCallback } from 'react';

import { BellRing } from 'assets/icons/BellRing';
import { Cancel } from 'assets/icons/Cancel';
import { Notification } from 'assets/icons/Notification';
import { Button } from 'components/Button/Button';
import { Column } from 'components/Layouts/Column/Column';
import { Row } from 'components/Layouts/Row/Row';
import { Menu } from 'components/Navigation/Menu';
import { dialogTriggerButton, userCardInsetSubHeader } from 'components/Navigation/Navigation.css';
import { NavigationMenuCard } from 'components/Navigation/NavigationMenuCard';
import { api } from 'store/api';
import { notificationSlice } from 'store/notifications';
import { useAppDispatch, useAppSelector } from 'store/store';
import { NotificationSeverity } from 'types';
import { NotificationBadge } from './NotificationBadge';
import { NotificationMessage } from './NotificationMessage';
import { emptyNotification, notification } from './ToastManager.css';

export const Notifications = () => {
    const dispatch = useAppDispatch();
    const messageObj = useAppSelector(notificationSlice.selectors.selectNotifications);

    const [markAsRead, result] = api.useLazyMarkAllAsReadQuery();
    const messages = Object.entries(messageObj);
    const messageCount = messages.length;

    const handleDismissAll = useCallback(() => {
        void markAsRead()
            .then(() => {
                dispatch(notificationSlice.actions.dismissAll());
            })
            .catch(() => {
                dispatch(
                    notificationSlice.actions.notify({
                        title: t({
                            id: 'Notification.MarkAsRead.Failed',
                            message: 'Failed to mark all as read',
                        }),
                        severity: NotificationSeverity.ERROR,
                    }),
                );
            });
    }, [dispatch, markAsRead, result.isError]);

    return (
        <Menu
            scroll={false}
            transformOrigin="top right"
            content={
                <NavigationMenuCard
                    position="none"
                    size="none"
                    header={
                        <Row alignItems="center" justifyContent="spaceBetween">
                            <h3 className={userCardInsetSubHeader}>
                                <Trans id="Notifications.Notifications">Notifications</Trans>
                            </h3>

                            <Button onPress={handleDismissAll} variant="blackSoft" color="textOnButton" size="small">
                                <Trans id="Notifications.Dismiss_all">Dismiss all</Trans>
                                <Cancel />
                            </Button>
                        </Row>
                    }
                >
                    <Column height="fit" gap="small" alignItems="start" className={notification}>
                        <AnimatePresence initial={false}>
                            {messageCount === 0 ? (
                                <Row
                                    gap="small"
                                    alignItems="center"
                                    justifyContent="center"
                                    width="full"
                                    className={emptyNotification}
                                >
                                    <h6>
                                        <Notification />
                                    </h6>
                                    <h6>
                                        <Trans id="Notifications.No_notifications">No notifications</Trans>
                                    </h6>
                                </Row>
                            ) : (
                                messages.map(([id, message]) => (
                                    <NotificationMessage
                                        key={id}
                                        id={id}
                                        padding="zero"
                                        showDateTimestamp
                                        {...message}
                                    />
                                ))
                            )}
                        </AnimatePresence>
                    </Column>
                </NavigationMenuCard>
            }
        >
            {(item) => (
                <Button
                    {...item}
                    color="accent"
                    shape="square"
                    size="large"
                    variant="muted"
                    className={dialogTriggerButton}
                >
                    <BellRing />
                    {messageCount > 0 && <NotificationBadge value={messageCount} />}
                </Button>
            )}
        </Menu>
    );
};
