import { createSlice } from '@reduxjs/toolkit';

export const popoverSlice = createSlice({
    name: 'popover',
    initialState: null as string | null,
    reducers: (create) => ({
        showPopover: create.reducer<string>((_state, { payload }) => payload),
        hidePopover: create.reducer<void>((_state, _action) => null),
    }),
    selectors: {
        selectPopover: (state) => state,
    },
});
