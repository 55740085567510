import type { ReactNode } from 'react';
import { useHasPermission, type PermissionOptions } from './useHasPermission';

export type PermissionProps = PermissionOptions &
    (
        | {
              children: ReactNode;
              pass?: never;
              fail?: never;
          }
        | {
              children?: never;
              pass: ReactNode;
              fail: ReactNode;
          }
    );

export const Permission = ({ ability, role, profile, children, pass = children, fail = null }: PermissionProps) => {
    const hasPermission = useHasPermission();

    return hasPermission({ ability, role, profile }) ? pass : fail;
};
