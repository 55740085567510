/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useLayoutEffect, useRef } from 'react';

/**
 * Like the [useEvent RFC](https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md) but implemented in userland as well as it goes.
 * @param func - unstable function
 * @returns stable function reference that will call the input function
 */
export const useEventHandler = <Fn extends (...data: any[]) => unknown>(func: Fn): Fn => {
    const funcRef = useRef(func);

    useLayoutEffect(() => {
        funcRef.current = func;
    }, [func]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(<Fn>((...args: Parameters<Fn>) => funcRef.current(...args)), []);
};
