import { t } from '@lingui/macro';
import { redirect, type ActionFunctionArgs } from 'react-router-dom';

import { getEntityName } from 'i18n/getEntityName';
import {
    NotificationSeverity,
    type ChangePasswordPayload,
    type Credentials,
    type EntityTypes,
    type ResetPasswordPayload,
} from 'types';
import { invariant } from 'utils/invariant';
import { isDefined } from 'utils/isDefined';
import { kebabToCamelCase } from 'utils/kebabToCamelCase';
import { accessSlice } from './accessSlice';
import { api, type EndpointNames } from './api';
import { notificationSlice } from './notifications';
import { store } from './store';
import type { ValidationErrorResponse } from './types';

const isValidationError = (error: unknown): error is ValidationErrorResponse =>
    isDefined(error) && 'status' in error && [422, 409].includes(error.status as number);

export const action =
    <EndpointName extends `${'create' | 'edit'}${string}` & EndpointNames>(
        endpointName: EndpointName,
        relative?: boolean,
        shouldRedirect: boolean = true,
    ) =>
    async ({ request }: ActionFunctionArgs) => {
        const record = await request.json();

        const path = request.url.replaceAll(/^https?:\/\/[^/]+\/([^/]+)\/.*/gm, '$1');

        const endpoint = api.endpoints[endpointName];
        // @ts-expect-error fix me
        const thunk = endpoint.initiate(record);
        // @ts-expect-error fix me
        const promise = store.dispatch(thunk) as { unwrap: () => Promise<unknown> };

        const entity = `${endpointName
            .replace(/^(create|edit)/, '')
            .replaceAll(/([a-z])([A-Z])/g, `$1-$2`)
            .replace(/^.+/, (match) => match.toLowerCase())}s` as EntityTypes;
        const amountAndType = getEntityName(entity);

        try {
            const response = await promise.unwrap();

            // @ts-expect-error fix me
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const id = (response?.id ?? response?.data?.id) as string;

            store.dispatch(
                notificationSlice.actions.notify({
                    title: endpointName.startsWith('edit')
                        ? t({
                              id: 'action.Successfully_edited',
                              message: `Successfully edited ${amountAndType}`,
                          })
                        : t({
                              id: 'action.Successfully_created',
                              message: `Successfully created ${amountAndType}`,
                          }),
                    severity: NotificationSeverity.SUCCESS,
                }),
            );

            return shouldRedirect ? redirect(relative ? '..' : `/${path}/${id}`) : null;
        } catch (error) {
            if (isValidationError(error)) {
                store.dispatch(
                    notificationSlice.actions.notify({
                        title: t({
                            id: 'action.Some_fields_are_not_filled_out_correctly',
                            message: 'Some fields are not filled out correctly',
                        }),
                        severity: NotificationSeverity.ERROR,
                    }),
                );

                return { status: 'invalid', errors: error.data.errors, data: record };
            }

            store.dispatch(
                notificationSlice.actions.notify({
                    title: t({
                        id: 'action.Something_went_wrong_while_performing_this_action',
                        message: 'Something went wrong while performing this action',
                    }),
                    severity: NotificationSeverity.ERROR,
                }),
            );

            throw error;
        }
    };

export const deleteAction =
    <EndpointName extends `${'delete'}${string}` & EndpointNames>(endpointName: EndpointName) =>
    async ({ params: { id }, request: { url } }: ActionFunctionArgs) => {
        invariant(isDefined(id), 'id must be defined');
        const endpoint = api.endpoints[endpointName];
        const thunk = endpoint.initiate(id);
        const promise = store.dispatch(thunk);

        const entity = kebabToCamelCase(new URL(url).pathname.split('/').find(Boolean)!) as EntityTypes;
        const amountAndType = getEntityName(entity);

        try {
            await promise.unwrap();

            store.dispatch(
                notificationSlice.actions.notify({
                    title: t({
                        id: 'deleteAction.Successfully_deleted',
                        message: `Successfully deleted ${amountAndType}`,
                    }),
                    severity: NotificationSeverity.SUCCESS,
                }),
            );

            if (url.includes(id)) {
                return redirect(`..`);
            }

            return { status: 'success', data: id };
        } catch (error) {
            store.dispatch(
                notificationSlice.actions.notify({
                    title: t({
                        id: 'deleteAction.Failed_to_delete',
                        message: `Failed to delete ${amountAndType}`,
                    }),
                    severity: NotificationSeverity.ERROR,
                }),
            );

            throw error;
        }
    };

export const loginAction = async ({ request }: ActionFunctionArgs) => {
    const credentials = (await request.json()) as Credentials;
    const promise = store.dispatch(api.endpoints.login.initiate(credentials));

    try {
        const { token, context } = await promise.unwrap();
        const pathname = window.localStorage.getItem('redirectPath') ?? '/';
        const search = window.localStorage.getItem('redirectSearch') ?? '';

        window.localStorage.removeItem('redirectPath');
        window.localStorage.removeItem('redirectSearch');

        if (context && 'id' in context) {
            store.dispatch(accessSlice.actions.selectAccess(context.id));
        }

        localStorage.setItem('token', token);
        return pathname.includes('login') ? redirect('/') : redirect(`${pathname}${search}`);
    } catch (error) {
        if (isValidationError(error)) {
            store.dispatch(
                notificationSlice.actions.notify({
                    title: t({
                        id: 'loginAction.Some_fields_are_not_filled_out_correctly',
                        message: 'Some fields are not filled out correctly',
                    }),
                    severity: NotificationSeverity.ERROR,
                }),
            );

            return { status: 'invalid', errors: error.data.errors, data: credentials };
        }

        if (error != null && typeof error === 'object' && 'status' in error && error.status === 403) {
            return redirect('/no-access');
        }

        store.dispatch(
            notificationSlice.actions.notify({
                title: t({
                    id: 'loginAction.Failed_to_log_in_for_unknown_reason',
                    message: 'Failed to log in for unknown reason',
                }),
                severity: NotificationSeverity.ERROR,
            }),
        );

        throw error;
    }
};

export const forgotPasswordAction = async ({ request }: ActionFunctionArgs) => {
    const credentials = (await request.json()) as { email: string };
    const promise = store.dispatch(api.endpoints.forgotPassword.initiate(credentials));

    try {
        await promise.unwrap();

        return { status: 'success', data: credentials };
    } catch (error) {
        if (isValidationError(error)) {
            store.dispatch(
                notificationSlice.actions.notify({
                    title: t({
                        id: 'forgotPasswordAction.Some_fields_are_not_filled_out_correctly',
                        message: 'Some fields are not filled out correctly',
                    }),
                    severity: NotificationSeverity.ERROR,
                }),
            );

            return { status: 'invalid', errors: error.data.errors, data: credentials };
        }

        store.dispatch(
            notificationSlice.actions.notify({
                title: t({
                    id: 'forgotPasswordAction.Failed_to_request_password_reset_for_unknown_reason',
                    message: 'Failed to request password reset for unknown reason',
                }),
                severity: NotificationSeverity.ERROR,
            }),
        );

        throw error;
    }
};

export const createUserAction = async ({ request }: ActionFunctionArgs) => {
    const credentials = (await request.json()) as {
        email: string;
        password: string;
        name: string;
        password_confirmation: string;
    };
    const promise = store.dispatch(api.endpoints.register.initiate(credentials));

    try {
        await promise.unwrap();

        return redirect('/');
    } catch (error) {
        if (isValidationError(error)) {
            store.dispatch(
                notificationSlice.actions.notify({
                    // eslint-disable-next-line lingui/no-unlocalized-strings
                    title: 'Het is niet gelukt om een account aan te maken',
                    severity: NotificationSeverity.ERROR,
                }),
            );

            return { status: 'invalid', errors: error.data.errors, data: credentials };
        }

        store.dispatch(
            notificationSlice.actions.notify({
                // eslint-disable-next-line lingui/no-unlocalized-strings
                title: 'Het is niet gelukt om een account aan te maken',
                severity: NotificationSeverity.ERROR,
            }),
        );

        throw error;
    }
};

export const changePasswordAction = async ({ request }: ActionFunctionArgs) => {
    const credentials = (await request.json()) as ChangePasswordPayload;
    const promise = store.dispatch(api.endpoints.changePassword.initiate(credentials));

    try {
        await promise.unwrap();
        return { status: 'success', data: credentials };
    } catch (error) {
        if (isValidationError(error)) {
            store.dispatch(
                notificationSlice.actions.notify({
                    title: t({
                        id: 'changePasswordAction.Some_fields_are_not_filled_out_correctly',
                        message: 'Some fields are not filled out correctly',
                    }),
                    severity: NotificationSeverity.ERROR,
                }),
            );

            return { status: 'invalid', errors: error.data.errors, data: credentials };
        }

        store.dispatch(
            notificationSlice.actions.notify({
                title: t({
                    id: 'changePasswordAction.Failed_to_reset_password_for_unknown_reason',
                    message: 'Failed to reset password for unknown reason',
                }),
                severity: NotificationSeverity.ERROR,
            }),
        );

        throw error;
    }
};

export const resetPasswordAction = async ({ request }: ActionFunctionArgs) => {
    const credentials = (await request.json()) as ResetPasswordPayload;
    const promise = store.dispatch(api.endpoints.resetPassword.initiate(credentials));

    try {
        await promise.unwrap();

        return { status: 'success', data: credentials };
    } catch (error) {
        if (isValidationError(error)) {
            store.dispatch(
                notificationSlice.actions.notify({
                    title: t({
                        id: 'resetPasswordAction.Some_fields_are_not_filled_out_correctly',
                        message: 'Some fields are not filled out correctly',
                    }),
                    severity: NotificationSeverity.ERROR,
                }),
            );

            return { status: 'invalid', errors: error.data.errors, data: credentials };
        }

        store.dispatch(
            notificationSlice.actions.notify({
                title: t({
                    id: 'resetPasswordAction.Failed_to_reset_password_for_unknown_reason',
                    message: 'Failed to reset password for unknown reason',
                }),
                severity: NotificationSeverity.ERROR,
            }),
        );

        throw error;
    }
};
