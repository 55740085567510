import * as RadixSeparator from '@radix-ui/react-separator';
import clsx from 'clsx';

import { enhance } from 'utils/enhance';
import { separator, type SeparatorVariants } from './Separator.css';

export const Separator = enhance<typeof RadixSeparator.Root, SeparatorVariants>(
    'Separator',
    ({ className, orientation, color, size, ...props }, ref) => (
        <RadixSeparator.Root
            {...props}
            className={clsx(separator({ orientation, color, size }), className)}
            ref={ref}
        />
    ),
);
