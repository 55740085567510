import type { Query } from 'store/types';
import type { UUID } from 'types';

export const optionsOptions = { fields: ['name', 'tenant_id', 'tenant_name'] };

export type Options = {
    fields?: string[];
    filters: Record<string, string | boolean>;
};

export const createOptionsHook =
    <Entity extends { id: string; name: string; tenant_id: UUID; tenant_name: string; code_name?: string }>(
        useQuery: Query<Entity[]>,
        options?: Options,
    ) =>
    () =>
        useQuery({ fields: [...optionsOptions.fields, ...(options?.fields ?? [])], ...options?.filters }).data ?? [];
