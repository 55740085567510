import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import { Button } from 'react-aria-components';
import { enhance } from 'utils/enhance';
import { navigationLink, type NavigationLinkVariants } from './NavMenuListLink.css';

export const NavMenuListLink = enhance<typeof NavLink, NavigationLinkVariants>(
    'NavMenuListLink',
    ({ children, className, to, color }, ref) => {
        return (
            <NavLink className={clsx(navigationLink({ color }), className)} to={to} ref={ref}>
                {children}
            </NavLink>
        );
    },
);

export const NavMenuButton = enhance<typeof Button, NavigationLinkVariants>(
    'NavMenuButton',
    ({ className, color, ...props }, ref) => (
        <Button {...props} className={clsx(navigationLink({ color }), className)} ref={ref} />
    ),
);
