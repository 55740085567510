import { type PanInfo } from 'framer-motion';
import { useEventHandler } from 'hooks/useEventHandler';

import { didSwipe } from './didSwipe';

export const useSwipe = (callback: () => void) =>
    useEventHandler((_event: Event, info: PanInfo) => {
        if (didSwipe(info)) {
            callback();
        }
    });
