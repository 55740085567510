import clsx from 'clsx';

import { m } from 'framer-motion';
import { enhance } from 'utils/enhance';
import { row, type RowVariants } from './Row.css';

export const Row = enhance<'div', RowVariants>(
    'Row',
    ({ className, justifyContent, alignItems, gap, width, height, wrap, flexGrow, ...props }, ref) => (
        <div
            {...props}
            className={clsx(row({ justifyContent, alignItems, gap, width, height, wrap, flexGrow }), className)}
            ref={ref}
        />
    ),
);

export const MotionRow = m(Row);
