import { createSlice } from '@reduxjs/toolkit';
import { delay, filter, map, merge, mergeMap, takeUntil } from 'rxjs';

import { ofType } from 'redux-observable';
import type { DateTime, NotificationSeverities } from 'types';
import { api } from './api';
import { stopNotifications, type AppEpic } from './store';

export type Notification = {
    id?: string;

    title?: string;
    message?: string;
    replacements?: Record<string, unknown>;

    severity: NotificationSeverities;
    important?: boolean;
    silent?: boolean;
    created_at?: DateTime;
};

const initialState = {
    toastMessages: {} as Record<string, Notification>,
    notifications: {} as Record<string, Notification>,
};

export const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: (create) => ({
        notify: create.preparedReducer(
            (notification: Notification) => ({
                payload: { id: notification.id ?? window.crypto.randomUUID(), notification },
            }),
            (
                state,
                {
                    payload: {
                        id,
                        notification: { silent, important, ...notification },
                    },
                },
            ) => {
                if (!silent) {
                    state.toastMessages[id] = notification;
                }
                if (important) {
                    state.notifications[id] = notification;
                }
            },
        ),

        dismissToast: create.reducer<string>((state, { payload }) => {
            delete state.toastMessages[payload];
        }),

        dismissAll: create.reducer((state) => {
            state.toastMessages = {};
            state.notifications = {};
        }),
    }),
    extraReducers: (builder) => {
        builder.addMatcher(
            api.endpoints.markAsRead.matchPending,
            (
                state,
                {
                    meta: {
                        arg: {
                            originalArgs: { id },
                        },
                    },
                },
            ) => {
                delete state.notifications[id];
            },
        );
    },
    selectors: {
        selectToastMessages: (state) => state.toastMessages,

        selectNotifications: (state) => state.notifications,
    },
});

export const createGetNotificationsThunk = (timeStamp?: string) => {
    const params: Record<string, string | string[]> = {
        fields: ['data', 'created_at', 'message'],
    };

    if (timeStamp) {
        params['filter[created_at]'] = `gte:${timeStamp}`;
    }

    return api.endpoints.getNotifications.initiate(params, {
        subscribe: false,
        forceRefetch: true,
    });
};

export const notificationEpic: AppEpic = (action$) =>
    merge(
        // then make a request every minute
        action$.pipe(
            filter(api.endpoints.getNotifications.matchFulfilled),
            map(() => new Date().toISOString()),
            delay(1000 * 20),
            map(createGetNotificationsThunk),
        ),

        // turn the responses into notifications
        action$.pipe(
            filter(api.endpoints.getNotifications.matchFulfilled),
            mergeMap((action) =>
                action.payload.map((notification) =>
                    notificationSlice.actions.notify({
                        id: notification.id,
                        message: notification.message,
                        replacements: notification.data.replacements,
                        severity: notification.data.severity,
                        important: true,
                        created_at: notification.created_at,
                        silent: action.meta.arg.originalArgs?.['filter[created_at]'] === undefined,
                    }),
                ),
            ),
        ),
    ).pipe(takeUntil(action$.pipe(ofType(stopNotifications.type))));
