/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import { m } from 'framer-motion';
import { useClickOutside } from 'hooks/useClickOutside';
import { type ReactNode, type RefObject } from 'react';
import { Overlay, usePopover } from 'react-aria';

export const Popover = ({
    children,
    triggerRef,
    transformOrigin,
    scroll = true,
    ...props
}: {
    children: ReactNode;
    triggerRef: RefObject<Element>;
    transformOrigin: string;
    isOpen: boolean;
    setOpen: (open: boolean) => void;
    open: () => void;
    close: () => void;
    toggle: () => void;
    scroll?: boolean;
}) => {
    const popoverRef = useClickOutside<HTMLDivElement>(props.close);

    const { popoverProps } = usePopover(
        {
            triggerRef,
            popoverRef,
            isNonModal: true,
        },
        props,
    );

    return (
        <Overlay portalContainer={scroll ? undefined : document.querySelector('header')!}>
            <div {...popoverProps} ref={popoverRef}>
                <m.div
                    initial={{ opacity: 0, scale: 0.5, transformOrigin }}
                    animate={{ opacity: 1, scale: 1, transformOrigin }}
                    exit={{ opacity: 0, scale: 0, transformOrigin }}
                >
                    {children}
                </m.div>
            </div>
        </Overlay>
    );
};
