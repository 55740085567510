// if (import.meta.env.DEV) {
//     const { worker } = await import('./mocks/browser');

//     await worker.start();
// }

import '@fontsource-variable/work-sans';
import '@total-typescript/ts-reset';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { router } from './router';
import './sentry';
import { invariant } from './utils/invariant';
import { isDefined } from './utils/isDefined';

const node = window.document.querySelector<HTMLDivElement>('#root');
invariant(isDefined(node));
createRoot(node).render(
    <StrictMode>
        <RouterProvider router={router} />
    </StrictMode>,
);

console.log(`🟦🟦🟦 █▀▀▀ █▀▀█ █▀▀█ ▀▀█▀▀ ▀█▀ █▀▀█
🟦🟦🟢 █▀▀  █▄▄█ ▀▀▄▄   █    █  ▀▀▄▄
🟦🟢🟢 █    █  █ █▄▄█   █   ▄█▄ █▄▄█`);

// eslint-disable-next-line lingui/no-unlocalized-strings
console.log(`Version built at ${VITE_GLOBAL_TIME}`);
