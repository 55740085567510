import { useNavigation } from 'react-router-dom';

import { progress, progressWrapper } from './PageLoading.css';

export const PageLoading = () => {
    const { state } = useNavigation();

    return (
        <div className={progressWrapper({ state })} aria-hidden>
            <div className={progress} />
            <div className={progress} />
            <div className={progress} />
            <div className={progress} />
        </div>
    );
};
