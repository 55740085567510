import {
    forwardRef,
    type ComponentPropsWithoutRef,
    type ElementRef,
    type ElementType,
    type ForwardRefRenderFunction,
} from 'react';
import type { Prettify } from './prettify';

export const enhance = <T extends ElementType, P = object>(
    displayName: string,
    render: ForwardRefRenderFunction<ElementRef<T>, Prettify<Omit<ComponentPropsWithoutRef<T>, keyof P> & P>>,
) => Object.assign(forwardRef(render), { displayName });
