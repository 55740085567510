import clsx from 'clsx';
import { Link as LinkPrimitive } from 'react-router-dom';

import { enhance } from 'utils/enhance';
import { link, type LinkVariants } from './Link.css';

export const Link = enhance<typeof LinkPrimitive, LinkVariants>(
    'Link',
    ({ size, color, bold, className, ...props }, ref) => {
        const finalProps = {
            ...props,
            className: clsx(link({ size, color, bold }), className),
            ref,
        };
        return typeof props.to === 'string' && props.to.startsWith('http') ? (
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a rel="noreferrer" target="_blank" {...finalProps} />
        ) : (
            <LinkPrimitive {...finalProps} />
        );
    },
);
