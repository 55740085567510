import { useMemo } from 'react';

import { mediaQuerySizes } from 'theme/mediaQuerySizes';
import { useMediaQuery } from './useMediaQuery';

export const useDevice = () => {
    const mobile = useMediaQuery(`(max-width:${mediaQuerySizes.laptop})`);
    const tablet = useMediaQuery(`(min-width:${mediaQuerySizes.tablet})`);
    const laptop = useMediaQuery(`(min-width:${mediaQuerySizes.laptop})`);
    const desktop = useMediaQuery(`(min-width:${mediaQuerySizes.desktop})`);

    return useMemo(
        () => ({
            mobile,
            tablet,
            laptop,
            desktop,
        }),
        [mobile, tablet, laptop, desktop],
    );
};
