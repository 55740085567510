import { WORD_IN_BRACKETS } from './regex';

export type TranslateOptions = {
    id: string;
    values?: Record<string, string>;
};

export type TranslateOptionsFunc = ({ id, values }: TranslateOptions) => string;

export const replaceWordsInTrans = ({ id, values }: TranslateOptions) =>
    id.replaceAll(WORD_IN_BRACKETS, (match: string, msg: string) => {
        return values?.[msg] || match;
    });
