import { init, reactRouterV6BrowserTracingIntegration } from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

init({
    dsn: VITE_GLOBAL_SENTRY_INGEST_URL,
    tunnel: VITE_GLOBAL_SENTRY_TUNNEL,
    enabled: import.meta.env.PROD,
    environment: VITE_GLOBAL_APP_ENV,
    release: VITE_GLOBAL_RELEASE,
    integrations: [
        reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
});
