import { createContext, type Dispatch, type SetStateAction } from 'react';

export const SearchParamsContext = createContext<
    | readonly [
          URLSearchParams,
          (mutator: (mutableSearchParams: URLSearchParams) => void) => void,
          Dispatch<SetStateAction<readonly [string, URLSearchParams]>>,
      ]
    | undefined
>(undefined);
