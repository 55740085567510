import { LazyMotion, MotionConfig } from 'framer-motion';
import type { ReactNode } from 'react';

import { preferencesSlice } from 'store/preferences';
import { useAppSelector } from 'store/store';

const loadFeatures = () => import('./features').then((module) => module.default);

export const MotionManager = ({ children }: { children?: ReactNode }) => {
    const animationPreference = useAppSelector(preferencesSlice.selectors.selectAnimationPreference);

    return (
        <MotionConfig reducedMotion={animationPreference}>
            <LazyMotion strict features={loadFeatures}>
                {children}
            </LazyMotion>
        </MotionConfig>
    );
};
