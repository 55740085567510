import { AnimatePresence } from 'framer-motion';
import { usePopover } from 'hooks/usePopover';
import { useEffect, useRef, type ReactNode, type RefObject } from 'react';
import type { ButtonProps } from 'react-aria-components';
import { useLocation } from 'react-router-dom';
import { Popover } from './Popover';

export const Menu = ({
    children: Trigger,
    content,
    transformOrigin,
    scroll,
}: {
    children: (item: { ref: RefObject<HTMLButtonElement> } & ButtonProps) => JSX.Element;
    content: ReactNode;
    transformOrigin: string;
    scroll?: boolean;
}) => {
    const triggerRef = useRef<HTMLButtonElement>(null);
    const { key } = useLocation();

    const { isOpen, open, close, toggle, setOpen } = usePopover();

    useEffect(() => {
        close();
    }, [close, key]);

    return (
        <>
            {Trigger({ onPress: open, ref: triggerRef })}
            <AnimatePresence>
                {isOpen && (
                    <Popover
                        triggerRef={triggerRef}
                        isOpen={isOpen}
                        setOpen={setOpen}
                        open={open}
                        close={close}
                        toggle={toggle}
                        transformOrigin={transformOrigin}
                        scroll={scroll}
                    >
                        {content}
                    </Popover>
                )}
            </AnimatePresence>
        </>
    );
};
