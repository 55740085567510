import { t } from '@lingui/macro';
import * as Toast from '@radix-ui/react-toast';
import { AnimatePresence } from 'framer-motion';
import { useCallback } from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';

import { notificationSlice } from 'store/notifications';
import { useAppSelector } from 'store/store';
import { viewport } from './ToastManager.css';
import { ToastMessage } from './ToastMessage';
import { UpdatePrompt } from './UpdatePrompt';

export const ToastManager = () => {
    const messages = useAppSelector(notificationSlice.selectors.selectToastMessages);

    const {
        needRefresh: [needRefresh, setNeedRefresh],
        updateServiceWorker,
    } = useRegisterSW({});

    const ignore = useCallback(() => {
        setNeedRefresh(false);
    }, [setNeedRefresh]);

    const apply = useCallback(() => {
        setNeedRefresh(false);
        void updateServiceWorker();
    }, [setNeedRefresh, updateServiceWorker]);

    return (
        <Toast.Provider
            label={t({
                id: 'ToastManager.Notifications',
                message: 'Notifications',
            })}
        >
            {needRefresh && <UpdatePrompt apply={apply} ignore={ignore} />}
            <AnimatePresence initial={false}>
                {Object.entries(messages).map(([id, message]) => (
                    <ToastMessage key={id} id={id} {...message} />
                ))}
            </AnimatePresence>
            <Toast.Viewport className={viewport} />
        </Toast.Provider>
    );
};
