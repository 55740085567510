import { useEventHandler } from 'hooks/useEventHandler';
import { useMemo, useState, type ReactNode } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { SearchParamsContext } from './SearchParamsContext';

// when you adjust the search params on the current page,
// I want components to react to those changes
// but it should reset immediately on page change
// a useEffect would be too slow
// remounting the provider didn't work
// I assume this works fine when the user navigates back because the url should still be in sync
// and the location key should be different for all navigations forward

export const SearchParamsProvider = ({ children }: { children: ReactNode }) => {
    const { key, pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const [[seenKey, modifiedSearchParams], setSearchParams] = useState<readonly [string, URLSearchParams]>(
        () => [key, searchParams] as const,
    );

    const mutateSearchParams = useEventHandler(
        (mutator: (mutableSearchParams: URLSearchParams) => URLSearchParams | void) => {
            let mutableSearchParams = new URLSearchParams(modifiedSearchParams);

            // the callback is expected to mutate the searchParams object
            // no need to create a new one or return it but its allowed
            mutableSearchParams = mutator(mutableSearchParams) ?? mutableSearchParams;

            // capture the current key
            setSearchParams([key, mutableSearchParams]);

            // set the url without hitting the router
            const newUrl = [pathname, mutableSearchParams.toString()].filter(Boolean).join('?');
            window.history.replaceState(null, '', newUrl);
        },
    );

    const value = useMemo(
        // if the key is the same, return modifiedSearchParams
        () => [key === seenKey ? modifiedSearchParams : searchParams, mutateSearchParams, setSearchParams] as const,
        [key, mutateSearchParams, searchParams, modifiedSearchParams, seenKey],
    );

    return <SearchParamsContext.Provider value={value}>{children}</SearchParamsContext.Provider>;
};
