import { useMediaQuery } from 'hooks/useMediaQuery';
import { useEffect } from 'react';

import { preferencesSlice } from 'store/preferences';
import { useAppSelector } from 'store/store';
import { darkThemeClass, lightThemeClass } from './theme.css';

export const ThemeManager = () => {
    const detected = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light';
    const preference = useAppSelector(preferencesSlice.selectors.selectThemePreference);

    useEffect(() => {
        const resolved = preference === 'auto' ? detected : preference;
        const className = resolved === 'dark' ? darkThemeClass : lightThemeClass;

        window.document.documentElement.classList.add(className);
        window.document.documentElement.style.colorScheme = resolved;

        return () => {
            window.document.documentElement.classList.remove(className);
            window.document.documentElement.style.colorScheme = 'unset';
        };
    }, [detected, preference]);

    return null;
};
