import { useEffect } from 'react';
import type { EventMapFor } from './EventMapFor';
import { useEventHandler } from './useEventHandler';

/**
 * Never call addEventListener again, just let this hook handle it
 * @example
 * ```tsx
 * const MyComponent = () => {
 *   useEventListener({
 *     eventName: "keydown",
 *     listener: event => console.log(event.key),
 *   });
 *
 *   return <div />;
 * }
 * ```
 */
export const useEventListener = <
    EventName extends keyof EventMapFor<Target> & string,
    Event extends EventMapFor<Target>[EventName],
    Target extends Window | HTMLElement | MediaQueryList = Window,
>({
    target,
    eventName,
    listener,
    active = true,
    passive = true,
    capture = false,
}: {
    target?: Target;
    eventName: EventName;
    listener: (event: Event) => void;
    active?: boolean;
    passive?: boolean;
    capture?: boolean;
}) => {
    const handleEvent = useEventHandler(listener);

    useEffect(() => {
        if (!active) return;

        const element = target ?? window;

        if ('addListener' in element) {
            element.addListener(handleEvent as EventListener);

            return () => {
                element.removeListener(handleEvent as EventListener);
            };
        }

        element.addEventListener(eventName, handleEvent as EventListener, { passive, capture });

        return () => {
            element.removeEventListener(eventName, handleEvent as EventListener);
        };
    }, [eventName, target, active, handleEvent, passive, capture]);
};
