import { t } from '@lingui/macro';
import * as Toast from '@radix-ui/react-toast';
import { useMemo, type MouseEvent } from 'react';

import { Cancel } from 'assets/icons/Cancel';
import { ChatAlert } from 'assets/icons/ChatAlert';
import { CheckCircle } from 'assets/icons/CheckCircle';
import { Info } from 'assets/icons/Info';
import { Button } from 'components/Button/Button';
import { MotionCard } from 'components/Card/Card';
import type { CardVariants } from 'components/Card/Card.css';
import { Column } from 'components/Layouts/Column/Column';
import { Row } from 'components/Layouts/Row/Row';
import { FormattedDateTime } from 'i18n/FormattedDateTime';
import { type Notification } from 'store/notifications';
import { hiddenShrunkSide, visible } from 'theme/motions';
import { enhance } from 'utils/enhance';
import { isDefined } from 'utils/isDefined';
import { formatToast } from './formatToast';
import { notificationCard, notificationContent, toastIcon, type NotificationCardVariants } from './ToastManager.css';

const preventDefault = (e: MouseEvent) => {
    e.preventDefault();
};

export type NotificationCard = {
    showDateTimestamp?: boolean;
    handleDismiss: () => void;
} & CardVariants &
    NotificationCardVariants;

export const NotificationCard = enhance<typeof MotionCard, Notification & NotificationCard>(
    'NotificationCard',
    (
        {
            title,
            severity,
            created_at,
            showDateTimestamp,
            padding,
            onDragEnd,
            handleDismiss,
            message,
            replacements,
            ...props
        },
        ref,
    ) => {
        const formatted = useMemo(
            () => (isDefined(message) ? formatToast(message, replacements) : title),
            [message, replacements, title],
        );

        return (
            <MotionCard
                ref={ref}
                layout
                drag="x"
                dragSnapToOrigin
                initial={hiddenShrunkSide}
                animate={visible}
                exit={hiddenShrunkSide}
                color="transparent"
                className={notificationCard({ padding })}
                onDragEnd={onDragEnd}
                onMouseDown={preventDefault}
                {...props}
            >
                <Row alignItems="start" gap="medium">
                    <span className={toastIcon({ severity })}>
                        {
                            {
                                ERROR: <ChatAlert />,
                                SUCCESS: <CheckCircle />,
                                NEUTRAL: <Info />,
                            }[severity]
                        }
                    </span>
                    {showDateTimestamp && created_at ? (
                        <Column className={notificationContent}>
                            <Row alignItems="center" justifyContent="spaceBetween">
                                <h6>
                                    <FormattedDateTime value={created_at} dateStyle="long" timeStyle="short" />
                                </h6>
                                <Button
                                    aria-label={t({
                                        id: 'NotificationCard.Dismiss',
                                        message: 'Dismiss',
                                    })}
                                    onPress={handleDismiss}
                                    variant="flat"
                                    size="small"
                                >
                                    <Cancel />
                                </Button>
                            </Row>
                            <Toast.Title asChild>
                                <h5>{formatted}</h5>
                            </Toast.Title>
                        </Column>
                    ) : (
                        <Row alignItems="center" justifyContent="spaceBetween" width="full" gap="small">
                            <Toast.Title asChild>
                                <h5>{formatted}</h5>
                            </Toast.Title>
                            <Button
                                aria-label={t({
                                    id: 'NotificationCard.Dismiss',
                                    message: 'Dismiss',
                                })}
                                onPress={handleDismiss}
                                variant="flat"
                                size="small"
                            >
                                <Cancel />
                            </Button>
                        </Row>
                    )}
                </Row>
            </MotionCard>
        );
    },
);
