import { endOfWeek, getLocalTimeZone, startOfWeek, today } from '@internationalized/date';
import { createSlice } from '@reduxjs/toolkit';
import { createTransform } from 'redux-persist';

import { stringifyAnyDate } from 'components/DatePicker/stringifyAnyDate';
import { detectLocale } from 'i18n/activateLocale';
import { persistSlice } from 'store/persistSlice';
import type { Layouts, TimeSpans } from './_types';
import { timeSpanUtils } from './_useTimeSpanUtils';

const now = today(getLocalTimeZone());

export type CalendarReduxState = {
    layout: Layouts;
    timeSpan: TimeSpans;
    start: string;
    end: string;
    page: number;
};

const initialState: CalendarReduxState = {
    layout: 'grid',
    timeSpan: 'week',
    start: stringifyAnyDate(startOfWeek(now, detectLocale())),
    end: stringifyAnyDate(endOfWeek(now, detectLocale())),
    page: 1,
};

const dateTransform = createTransform(
    (value) => value,
    (value, key, { timeSpan }: { timeSpan: string; start: string; end: string }) => {
        const time = JSON.parse(timeSpan) as TimeSpans;
        const { startOf, endOf } = timeSpanUtils(detectLocale());

        if (key === 'start') return stringifyAnyDate(startOf(time, now));
        if (key === 'end') return stringifyAnyDate(endOf(time, now));
        return value;
    },
    {
        whitelist: ['start', 'end'],
    },
);

export const calendarSlice = persistSlice(
    createSlice({
        name: 'calendar',
        initialState,
        reducers: (create) => ({
            setCalendar: create.reducer<CalendarReduxState>((_state, action) => action.payload),
        }),
        selectors: {
            selectCalendar: (state) => state,
        },
    }),
    {
        transforms: [dateTransform],
    },
);
