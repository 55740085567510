import * as Toast from '@radix-ui/react-toast';

import { Trans } from '@lingui/macro';
import { BugOff } from 'assets/icons/BugOff';
import { Button } from 'components/Button/Button';
import { MotionCard } from 'components/Card/Card';
import { Column } from 'components/Layouts/Column/Column';
import { Row } from 'components/Layouts/Row/Row';
import { hiddenShrunkSide, visible } from 'theme/motions';
import { notificationCard, toastIcon } from './ToastManager.css';
import { useSwipe } from './useSwipe';

export const UpdatePrompt = ({ apply, ignore }: { apply: () => void; ignore: () => void }) => {
    const onDragEnd = useSwipe(ignore);

    return (
        <Toast.Root forceMount open type={'foreground'} onOpenChange={ignore} asChild>
            <MotionCard
                layout
                drag="x"
                dragSnapToOrigin
                initial={hiddenShrunkSide}
                animate={visible}
                exit={hiddenShrunkSide}
                className={notificationCard()}
                onDragEnd={onDragEnd}
            >
                <Row alignItems="start" gap="medium">
                    <span className={toastIcon()}>
                        <BugOff />
                    </span>
                    <Column gap="small">
                        <Toast.Title>
                            <h3>
                                <Trans id="UpdatePrompt.There_are_updates_available">There are updates available</Trans>
                            </h3>
                        </Toast.Title>

                        <Row justifyContent="spaceBetween" gap="small">
                            <Button color="brand" variant="primary" onPress={apply}>
                                <Trans id="UpdatePrompt.Update">Update</Trans>
                            </Button>
                            <Button onPress={ignore}>
                                <Trans id="UpdatePrompt.Ignore">Ignore</Trans>
                            </Button>
                        </Row>
                    </Column>
                </Row>
            </MotionCard>
        </Toast.Root>
    );
};
