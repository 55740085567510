import { createSlice } from '@reduxjs/toolkit';
import type { Locales } from 'locales';
import { persistSlice } from './persistSlice';

export type ThemeOptions = 'auto' | 'light' | 'dark';
export type LanguageOptions = 'auto' | Locales;
export type AnimationOptions = 'always' | 'never' | 'user';

const initialState = {
    theme: 'auto' as ThemeOptions,
    language: 'auto' as LanguageOptions,
    animation: 'user' as AnimationOptions,
    promptDirtyForm: true,
};

export type PreferenceState = typeof initialState;

export const preferencesSlice = persistSlice(
    createSlice({
        name: 'preferences',
        initialState,
        reducers: (create) => ({
            setPreferences: create.reducer<PreferenceState>((_state, { payload }) => payload),

            setTheme: create.reducer<ThemeOptions>((state, { payload }) => {
                state.theme = payload;
            }),

            setLanguage: create.reducer<LanguageOptions>((state, { payload }) => {
                state.language = payload;
            }),

            setAnimation: create.reducer<AnimationOptions>((state, { payload }) => {
                state.animation = payload;
            }),

            setPromptDirtyForm: create.reducer<boolean>((state, { payload }) => {
                state.promptDirtyForm = payload;
            }),
        }),
        selectors: {
            selectPreferences: (sliceState) => sliceState,
            selectThemePreference: (sliceState) => sliceState.theme,
            selectLanguagePreference: (sliceState) => sliceState.language,
            selectAnimationPreference: (sliceState) => sliceState.animation,
            selectPromptDirtyForm: (sliceState) => sliceState.promptDirtyForm,
        },
    }),
);
