import { useId } from 'react';

import { popoverSlice } from 'store/popoverSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useEventHandler } from './useEventHandler';

export const usePopover = () => {
    const id = useId();
    const dispatch = useAppDispatch();

    const isOpen = useAppSelector((state) => popoverSlice.selectors.selectPopover(state) === id);

    const open = useEventHandler(() => {
        dispatch(popoverSlice.actions.showPopover(id));
    });

    const close = useEventHandler(() => {
        dispatch(popoverSlice.actions.hidePopover());
    });

    const toggle = useEventHandler(() => {
        isOpen ? close() : open();
    });

    const setOpen = useEventHandler((value: boolean) => {
        value ? open() : close();
    });

    return { isOpen, open, close, toggle, setOpen };
};
