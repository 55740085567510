import { MotionCard } from 'components/Card/Card';
import type { CardVariants } from 'components/Card/Card.css';
import { CardInset } from 'components/CardInset/CardInset';
import type { CardInsetVariants } from 'components/CardInset/CardInset.css';
import type { ReactNode } from 'react';
import { navMenuCard, navMenuCardContent, navMenuCardHeader } from './Navigation.css';

export const NavigationMenuCard = ({
    children,
    header,
    position = 'top',
    ...cardVariants
}: { children: ReactNode; header: ReactNode } & CardVariants & CardInsetVariants) => {
    return (
        <MotionCard {...cardVariants} className={navMenuCard} layout shadow>
            <CardInset position={position} color="accent" className={navMenuCardHeader}>
                {header}
            </CardInset>

            <div className={navMenuCardContent}>{children}</div>
        </MotionCard>
    );
};
