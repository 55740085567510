import { createApi } from '@reduxjs/toolkit/query/react';

import type { AssignArgs } from 'pages/shifts/_AssignShiftModal';
import {
    EntityType,
    listAbsenceFields,
    listAbsenceMutationFields,
    listAbsenceTypeFields,
    listAdministratorFields,
    listInvoiceCollectionFields,
    listInvoiceFields,
    listLocationFields,
    listPricingFields,
    listProfessionFields,
    listProfileFields,
    listScheduleFields,
    listScheduleSlotFields,
    listScheduleStepsFields,
    listShiftFields,
    listSkillFields,
    listTemplateFields,
    listTenantFields,
    listTimeslotFields,
    listUploadTypeFields,
    ProfileType,
    ScheduleStepStatus,
    shiftDetailFields,
    type Absence,
    type AbsenceMutation,
    type AbsenceType,
    type Activity,
    type Administrator,
    type ChangePasswordPayload,
    type Credentials,
    type Declaration,
    type DetailShift,
    type Invitation,
    type Invoice,
    type InvoiceCollection,
    type ListAbsence,
    type ListAbsenceMutation,
    type ListAbsenceType,
    type ListAdministrator,
    type ListInvoice,
    type ListInvoiceCollection,
    type ListLocation,
    type ListPricing,
    type ListProfession,
    type ListProfile,
    type ListSchedule,
    type ListScheduleShifts,
    type ListScheduleSlot,
    type ListScheduleStep,
    type ListShift,
    type ListSkill,
    type ListTemplate,
    type ListTenant,
    type ListTimeslot,
    type ListUploadTypes,
    type Location,
    type LoginResponse,
    type Notification,
    type Pricing,
    type Profession,
    type Profile,
    type ResetPasswordPayload,
    type Schedule,
    type ScheduleResult,
    type ScheduleShift,
    type ScheduleSlot,
    type ScheduleStep,
    type Shift,
    type ShiftMarketTypes,
    type ShiftOption,
    type Skill,
    type Template,
    type TempUserData,
    type Tenant,
    type Timeslot,
    type UploadType,
    type User,
    type UUID,
} from 'types';
import { toHashMap } from 'utils/toHashMap';
import { baseQuery } from './baseQuery';
import type { ApiRecord } from './types';
import {
    create,
    exportManyTo,
    exportOneTo,
    exportOneToAccounting,
    flattributes,
    getAll,
    getMany,
    getManyRelated,
    getManyWithPageRelationshipsPaginated,
    getManyWithRelationships,
    getOne,
    getOneWithRelationships,
    massAction,
    remove,
    update,
} from './verbs';

export const api = createApi({
    reducerPath: 'api',
    baseQuery,
    tagTypes: Object.values(EntityType),
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponse, Credentials>({
            query: (body) => ({
                url: '/auth/login',
                method: 'POST',
                body,
            }),
        }),

        logout: builder.mutation<void, void>({
            query: () => ({
                url: '/auth/logout',
                method: 'POST',
            }),
        }),

        invite: builder.query<Invitation, void>({
            query: () => ({
                url: `/auth/invite`,
            }),
        }),

        register: builder.query<void, { email: string; password: string; name: string; password_confirmation: string }>(
            {
                query: (body) => ({
                    url: `/auth/register`,
                    method: 'POST',
                    body,
                }),
            },
        ),

        forgotPassword: builder.mutation<void, { email: string }>({
            query: (body) => ({
                url: '/auth/forgot-password',
                method: 'POST',
                body,
            }),
        }),

        changePassword: builder.mutation<void, ChangePasswordPayload>({
            query: (body) => ({
                url: '/auth/user/password',
                method: 'PUT',
                body,
            }),
        }),

        resetPassword: builder.mutation<void, ResetPasswordPayload>({
            query: (body) => ({
                url: '/auth/reset-password',
                method: 'POST',
                body,
            }),
        }),

        getMe: builder.query<TempUserData, void>({
            query: () => ({
                url: '/auth/user',
            }),
        }),

        editUserProfile: builder.mutation<void, Partial<User>>({
            query: (body) => ({
                url: `/auth/user`,
                method: 'PUT',
                body,
            }),
            async onQueryStarted(_submittedData, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData('getMe', undefined, (draft) => {
                            Object.assign(draft, data);
                        }),
                    );
                } catch {
                    // noop
                }
            },
        }),

        getProposalFor: builder.query<
            Absence['registration'],
            { profile_id: UUID; start_date: string; start_time?: string; end_date: string; end_time?: string }
        >({
            query: (params) => ({
                url: `v1/absences/-actions/get-proposal-for`,
                method: 'GET',
                params,
            }),
        }),

        markAllAsRead: builder.query<Notification, void>({
            query: () => ({
                url: `v1/notifications/-actions/mark-all-as-read`,
                method: 'GET',
            }),
        }),

        createUploadType: create<UploadType>(builder, EntityType.uploadTypes),
        editUploadType: update<UploadType>(builder, EntityType.uploadTypes),
        getUploadType: getOne<UploadType>(builder, EntityType.uploadTypes),
        getUploadTypes: getAll<UploadType>(builder, EntityType.uploadTypes),
        listUploadTypes: getMany<ListUploadTypes>(builder, EntityType.uploadTypes, listUploadTypeFields),
        deleteUploadType: remove(builder, EntityType.uploadTypes),
        massDeleteUploadTypes: massAction(builder, EntityType.uploadTypes, 'delete'),

        getTenantInfo: getOne<Tenant>(builder, EntityType.tenants),
        editTenantInfo: update<Tenant>(builder, EntityType.tenants),

        getMarketplaceOptions: getManyWithRelationships<ShiftOption & { trade_shift: Shift }>(
            builder,
            EntityType.shiftOptions,
            {
                sort: '-created_at',
                include: 'trade_shift',
            },
        ),

        removeMarketplaceOption: builder.mutation<void, { option: UUID; shiftId: UUID }>({
            query: ({ option, shiftId }) => ({
                url: `v1/shifts/${shiftId}/-actions/market-cancel`,
                method: 'POST',
                body: {
                    optionId: option,
                },
            }),
            invalidatesTags: (_, __, { option }) => [{ type: EntityType.shiftOptions, option }],
        }),
        createMarketplaceOptions: create<
            Pick<ShiftOption, 'shift_id' | 'profile_id'> & Partial<Pick<ShiftOption, 'trade_shift_id'>>
        >(builder, EntityType.shiftOptions),

        editMarketPlaceStatus: builder.mutation<void, { type?: ShiftMarketTypes; id: string }>({
            query: ({ id, ...body }) => ({
                url: `/v1/shifts/${id}/-actions/market`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (_, __, { id }) => [
                { type: EntityType.shifts, id },
                { type: EntityType.shiftOptions, id: 'LIST' },
            ],
        }),

        editAssignMarketplaceOffer: builder.mutation<void, { optionId: UUID; id: UUID; market_type: ShiftMarketTypes }>(
            {
                query: ({ optionId, id }) => ({
                    url: `v1/shifts/${id}/-actions/market-assign`,
                    method: 'POST',
                    body: {
                        optionId: optionId,
                    },
                }),
                invalidatesTags: (_, __, { id }) => [
                    { type: EntityType.shifts, id },
                    { type: EntityType.shiftOptions, id: 'LIST' },
                ],
            },
        ),

        assign: builder.mutation<void, AssignArgs>({
            query: ({ id, ...body }) => ({
                url: `/v1/shifts/${id}/-actions/assign`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (_, __, { id }) => [{ type: 'shifts', id }],
        }),

        getDeclaration: getOne<Declaration>(builder, EntityType.declarations),
        getDeclarations: getAll<Declaration>(builder, EntityType.declarations),
        createDeclaration: create<{ shift_date?: Shift['shift_date']; id: string }>(builder, EntityType.declarations),
        editDeclaration: update<Declaration>(builder, EntityType.declarations),
        deleteDeclaration: remove(builder, EntityType.declarations),

        exportAbsences: exportManyTo('csv', builder, EntityType.absences),
        createAbsenceMutation: create<AbsenceMutation>(builder, EntityType.absenceMutation),
        getAbsenceMutation: getOne<AbsenceMutation>(builder, EntityType.absenceMutation),
        listAbsenceMutation: getMany<ListAbsenceMutation>(
            builder,
            EntityType.absenceMutation,
            listAbsenceMutationFields,
        ),
        editAbsenceMutation: update<AbsenceMutation>(builder, EntityType.absenceMutation),
        deleteAbsenceMutation: remove(builder, EntityType.absenceMutation),
        massDeleteAbsenceMutation: massAction(builder, EntityType.absenceMutation, 'delete'),

        getAbsence: getOne<Absence>(builder, EntityType.absences),
        getAbsences: getAll<Absence>(builder, EntityType.absences),
        listAbsences: getMany<ListAbsence>(builder, EntityType.absences, listAbsenceFields),
        editAbsence: update<Absence>(builder, EntityType.absences),
        createAbsence: create<Absence>(builder, EntityType.absences),
        deleteAbsence: remove(builder, EntityType.absences),
        massDeleteAbsence: massAction(builder, EntityType.absences, 'delete'),
        approveAbsence: builder.mutation<void, { id: string }>({
            query: ({ id }) => ({
                url: `/v1/absences/${id}/-actions/approve`,
                method: 'POST',
            }),
            invalidatesTags: (_, __, { id }) => [{ type: 'absences', id }],
        }),
        denyAbsence: builder.mutation<void, { id: string }>({
            query: ({ id }) => ({
                url: `/v1/absences/${id}/-actions/deny`,
                method: 'POST',
            }),
            invalidatesTags: (_, __, { id }) => [{ type: 'absences', id }],
        }),

        getAbsenceType: getOne<AbsenceType>(builder, EntityType.absenceTypes),
        getAbsenceTypes: getAll<AbsenceType>(builder, EntityType.absenceTypes),
        listAbsenceTypes: getMany<ListAbsenceType>(builder, EntityType.absenceTypes, listAbsenceTypeFields),
        editAbsenceType: update<AbsenceType>(builder, EntityType.absenceTypes),
        createAbsenceType: create<AbsenceType>(builder, EntityType.absenceTypes),
        deleteAbsenceType: remove(builder, EntityType.absenceTypes),
        massDeleteAbsenceTypes: massAction(builder, EntityType.absenceTypes, 'delete'),

        exportInvoiceCollections: exportManyTo('csv', builder, EntityType.invoiceCollections),
        createInvoiceCollection: create<InvoiceCollection>(builder, EntityType.invoiceCollections),
        editInvoiceCollection: update<InvoiceCollection>(builder, EntityType.invoiceCollections),
        getInvoiceCollectionRelatedInvoices: getManyRelated<Invoice>(
            builder,
            EntityType.invoiceCollections,
            'invoices',
        ),
        getInvoiceCollection: getOneWithRelationships<InvoiceCollection>(builder, EntityType.invoiceCollections, {}),
        listInvoiceCollection: getMany<ListInvoiceCollection>(
            builder,
            EntityType.invoiceCollections,
            listInvoiceCollectionFields,
        ),
        deleteInvoiceCollection: remove(builder, EntityType.invoiceCollections),
        massDeleteInvoiceCollection: massAction(builder, EntityType.invoiceCollections, 'delete'),

        lockInvoiceCollections: builder.mutation<void, { id: string }>({
            query: ({ id, ...params }) => ({
                url: `/v1/${EntityType.invoiceCollections}/${id}/-actions/lock-collection`,
                method: 'POST',
                params,
            }),
            invalidatesTags: (_result, _error, { id }) => [{ type: EntityType.invoiceCollections, id }],
        }),
        exportInvoiceCollectionSepa: exportOneToAccounting('sepa', builder, EntityType.invoiceCollections),
        exportInvoiceCollectionLines: exportOneToAccounting('lines', builder, EntityType.invoiceCollections),

        exportInvoices: exportManyTo('csv', builder, EntityType.invoices),
        getInvoice: getOne<Invoice>(builder, EntityType.invoices),
        getInvoices: getAll<Invoice>(builder, EntityType.invoices),
        listInvoice: getMany<ListInvoice>(builder, EntityType.invoices, listInvoiceFields),
        editInvoice: update<Invoice>(builder, EntityType.invoices),
        createInvoice: create<Invoice>(builder, EntityType.invoices),
        deleteInvoice: remove(builder, EntityType.invoices),
        exportInvoice: exportOneTo('pdf', builder, EntityType.invoices),
        massDeleteInvoice: massAction(builder, EntityType.invoices, 'delete'),

        getLocation: getOne<Location>(builder, EntityType.locations),
        getLocations: getAll<Location>(builder, EntityType.locations),
        listLocations: getMany<ListLocation>(builder, EntityType.locations, listLocationFields),
        editLocation: update<Location>(builder, EntityType.locations),
        createLocation: create<Location>(builder, EntityType.locations),
        deleteLocation: remove(builder, EntityType.locations),
        massDeleteLocation: massAction(builder, EntityType.locations, 'delete'),

        getProfession: getOne<Profession>(builder, EntityType.professions),
        getProfessions: getAll<Profession>(builder, EntityType.professions),
        listProfessions: getMany<ListProfession>(builder, EntityType.professions, listProfessionFields),
        editProfession: update<Profession>(builder, EntityType.professions),
        createProfession: create<Profession>(builder, EntityType.professions),
        deleteProfession: remove(builder, EntityType.professions),
        massDeleteProfession: massAction(builder, EntityType.professions, 'delete'),

        getPricing: getOne<Pricing>(builder, EntityType.pricings),
        getPricings: getAll<Pricing>(builder, EntityType.pricings),
        listPricings: getMany<ListPricing>(builder, EntityType.pricings, listPricingFields),
        editPricing: update<Pricing>(builder, EntityType.pricings),
        createPricing: create<Pricing>(builder, EntityType.pricings),
        deletePricing: remove(builder, EntityType.pricings),
        massDeletePricing: massAction(builder, EntityType.pricings, 'delete'),

        getProfile: getOne<Profile>(builder, EntityType.profiles),
        getProfiles: getAll<Profile>(builder, EntityType.profiles),
        getIndividualProfiles: getAll<Profile>(builder, EntityType.profiles, {
            'filter[profile_type]': ProfileType.INDIVIDUAL,
        }),
        getBusinessProfiles: getAll<Profile>(builder, EntityType.profiles, {
            'filter[profile_type]': ProfileType.BUSINESS,
        }),
        getGroupProfiles: getAll<Profile>(builder, EntityType.profiles, {
            'filter[profile_type]': ProfileType.GROUP,
        }),
        exportProfiles: exportManyTo('csv', builder, EntityType.profiles),
        listProfiles: getMany<ListProfile>(builder, EntityType.profiles, listProfileFields),
        editProfile: update<Profile>(builder, EntityType.profiles),
        createProfile: create<Profile>(builder, EntityType.profiles),
        deleteProfile: remove(builder, EntityType.profiles),
        massDeleteProfile: massAction(builder, EntityType.profiles, 'delete'),
        unassignProfiles: massAction(builder, EntityType.shifts, 'unassign-profile'),
        assignProfiles: massAction(builder, EntityType.shifts, 'assign-profile'),

        createScheduleResult: builder.mutation<ScheduleResult, Pick<ScheduleResult, 'id'>>({
            query: ({ id, ...params }) => ({
                url: `/v1/schedule-steps/${id}/-actions/create-result`,
                method: 'POST',
                params,
            }),
            invalidatesTags: (_result, _error) => [
                { type: EntityType.scheduleResults, id: 'LIST' },
                { type: EntityType.scheduleSteps, id: _result?.schedule_step_id },
            ],
        }),
        getScheduleResults: getMany<ScheduleResult>(builder, EntityType.scheduleResults, [
            'created_at',
            'status',
            'extract',
        ]),
        getSchedule: getOne<Schedule>(builder, EntityType.schedules),
        getSchedules: getAll<Schedule>(builder, EntityType.schedules),
        listSchedules: getMany<ListSchedule>(builder, EntityType.schedules, listScheduleFields),
        editSchedule: update<Schedule>(builder, EntityType.schedules),
        createSchedule: create<Schedule>(builder, EntityType.schedules),
        deleteSchedule: remove(builder, EntityType.schedules),
        massDeleteSchedule: massAction(builder, EntityType.schedules, 'delete'),
        getScheduleWithSteps: getManyWithRelationships<Schedule>(builder, EntityType.schedules, {
            sort: '-created_at',
            include: 'schedule_steps',
        }),

        getScheduleResultSupplyAndDemand: builder.query<Pick<ScheduleResult, 'id'>, string>({
            query: (resultId) => ({
                url: `/v1/schedule-results/${resultId}`,
                params: {},
            }),
            transformResponse: (response: { data: ApiRecord }) => flattributes(response.data),
        }),

        getScheduleResultSDProfile: builder.query<Pick<ScheduleResult, 'id'>, string>({
            query: (resultId) => ({
                url: `/v1/schedule-results/${resultId}`,
                params: {},
            }),
            transformResponse: (response: { data: ApiRecord }) => flattributes(response.data),
        }),

        getScheduleShifts: getAll<ScheduleShift>(builder, EntityType.scheduleShifts, {
            sort: 'sorting',
        }),
        listScheduleShifts: getManyWithPageRelationshipsPaginated<ListScheduleShifts>(
            builder,
            EntityType.scheduleShifts,
            {
                include: 'current_schedule_options',
            },
        ),
        listScheduleConceptShifts: getManyWithPageRelationshipsPaginated<ListScheduleShifts>(
            builder,
            EntityType.scheduleShifts,
            {},
        ),
        editScheduleSlotToggle: builder.mutation<void, { profile_id: string; schedule_slot_id: string }>({
            query: ({ profile_id, schedule_slot_id, ...rest }) => ({
                url: `/v1/schedule-shifts/${schedule_slot_id}/-actions/toggle-option`,
                method: 'POST',
                body: {
                    profile_id,
                    ...rest,
                },
            }),
            transformResponse: (response: { data: ApiRecord }) => flattributes(response.data),
            invalidatesTags: (_result, _error) => [{ type: EntityType.scheduleShifts, id: 'LIST' }],
        }),

        editScheduleSlotOptationRemove: builder.mutation<void, { profile_id: string; schedule_slot_id: string }>({
            query: ({ profile_id, schedule_slot_id }) => ({
                url: `/v1//schedule-shifts/${schedule_slot_id}/-actions/remove-option`,
                method: 'POST',
                body: {
                    profile_id,
                },
            }),
            transformResponse: (response: { data: ApiRecord }) => flattributes(response.data),
            invalidatesTags: (_result, _error) => [{ type: EntityType.scheduleShifts, id: 'LIST' }],
        }),
        getScheduleSlot: getOne<ScheduleSlot>(builder, EntityType.scheduleSlots),
        getScheduleSlots: getAll<ScheduleSlot>(builder, EntityType.scheduleSlots),
        listScheduleSlots: getMany<ListScheduleSlot>(builder, EntityType.scheduleSlots, listScheduleSlotFields),
        editScheduleSlot: update<ScheduleSlot>(builder, EntityType.scheduleSlots),
        getScheduleSlotWithTemplates: getManyWithRelationships<ScheduleSlot>(builder, EntityType.scheduleSlots, {
            sort: '-created_at',
            include: 'template',
        }),

        createScheduleSlot: builder.mutation<void, ScheduleSlot>({
            query: ({ schedule_id, ...body }) => ({
                url: `/v1/schedules/${schedule_id}/-actions/create-slots`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (_, __, { id }) => [{ type: 'schedule-slots', id }],
        }),
        deleteScheduleSlot: remove(builder, EntityType.scheduleSlots),

        getScheduleStep: getOne<ScheduleStep>(builder, EntityType.scheduleSteps),
        getScheduleStepActive: getOne<ScheduleStep>(builder, EntityType.scheduleSteps, {
            'filter[status]': ScheduleStepStatus.ACTIVE,
        }),
        getScheduleSteps: getAll<ScheduleStep>(builder, EntityType.scheduleSteps),
        listScheduleSteps: getManyWithRelationships<ListScheduleStep>(
            builder,
            EntityType.scheduleSteps,
            {},
            listScheduleStepsFields,
        ),
        editScheduleStep: update<ScheduleStep>(builder, EntityType.scheduleSteps),
        createScheduleStep: builder.mutation<ScheduleStep, ScheduleStep>({
            query: ({ ...attributes }) => ({
                url: `/v1/${EntityType.scheduleSteps}`,
                method: 'POST',
                body: {
                    data: {
                        type: EntityType.scheduleSteps,
                        attributes,
                    },
                },
            }),
            invalidatesTags: (_, __, { schedule_id }) => [
                { type: EntityType.scheduleSteps, id: 'LIST' },
                { type: EntityType.schedules, id: schedule_id },
            ],
        }),

        deleteScheduleStep: remove(builder, EntityType.scheduleSteps),

        getAssignedShifts: getMany<Shift>(builder, EntityType.shifts, ['name', 'id'], {
            'filter[assigned]': '1',
        }),
        getShiftWithRelationship: getOneWithRelationships<DetailShift>(builder, EntityType.shifts, {
            'fields[shifts]': shiftDetailFields.join(','),
        }),

        getCalendarHeatmap: builder.query<
            Record<string, Record<'shift_count' | 'unassigned_count', string | number>>,
            { search: Record<string, string> }
        >({
            query: ({ search }) => ({ url: '/v1/calendars', params: search }),
            providesTags: [{ type: EntityType.shifts, id: 'LIST' }],
            transformResponse: (res: {
                data: {
                    type: 'calendars';
                    id: string;
                    attributes: {
                        shift_date: string;
                        shift_count: number;
                        unassigned_count: number;
                    };
                }[];
            }) =>
                toHashMap(
                    (item) => item.attributes.shift_date,
                    (item) => ({
                        shift_count: item.attributes.shift_count,
                        unassigned_count: item.attributes.unassigned_count,
                    }),
                    res.data,
                ),
        }),

        getShift: getOne<Shift>(builder, EntityType.shifts),
        getShifts: getAll<Shift>(builder, EntityType.shifts),
        listShifts: getMany<ListShift>(builder, EntityType.shifts, listShiftFields),
        editShift: update<Shift>(builder, EntityType.shifts),
        createShift: create<Shift>(builder, EntityType.shifts),
        deleteShift: remove(builder, EntityType.shifts),
        massDeleteShift: massAction(builder, EntityType.shifts, 'delete'),
        exportShifts: exportManyTo('csv', builder, EntityType.shifts),
        listMarketplace: getMany<ListShift>(builder, EntityType.shifts, listShiftFields, {
            ['filter[marketplace]']: '1',
        }),
        massAssignShiftsToProfile: massAction(builder, EntityType.shifts, 'assign-profile'),

        getSkill: getOne<Skill>(builder, EntityType.skills),
        getSkills: getAll<Skill>(builder, EntityType.skills),
        listSkills: getMany<ListSkill>(builder, EntityType.skills, listSkillFields),
        editSkill: update<Skill>(builder, EntityType.skills),
        createSkill: create<Skill>(builder, EntityType.skills),
        deleteSkill: remove(builder, EntityType.skills),
        massDeleteSkills: massAction(builder, EntityType.skills, 'delete'),

        exportTemplates: exportManyTo('csv', builder, EntityType.templates),
        getTemplate: getOne<Template>(builder, EntityType.templates),
        getTemplates: getAll<Template>(builder, EntityType.templates),
        listTemplates: getMany<ListTemplate>(builder, EntityType.templates, listTemplateFields),
        editTemplate: update<Template>(builder, EntityType.templates),
        createTemplate: create<Template>(builder, EntityType.templates),
        deleteTemplate: remove(builder, EntityType.templates),
        massDeleteTemplate: massAction(builder, EntityType.templates, 'delete'),

        getTenant: getOne<Tenant>(builder, EntityType.tenants),
        getTenants: getAll<Tenant>(builder, EntityType.tenants),
        listTenants: getMany<ListTenant>(builder, EntityType.tenants, listTenantFields),
        editTenant: update<Tenant>(builder, EntityType.tenants),
        createTenant: create<Tenant>(builder, EntityType.tenants),
        deleteTenant: remove(builder, EntityType.tenants),

        getTimeslot: getOne<Timeslot>(builder, EntityType.timeslots),
        getTimeslots: getAll<Timeslot>(builder, EntityType.timeslots),
        listTimeslots: getMany<ListTimeslot>(builder, EntityType.timeslots, listTimeslotFields),
        editTimeslot: update<Timeslot>(builder, EntityType.timeslots),
        createTimeslot: create<Timeslot>(builder, EntityType.timeslots),
        deleteTimeslot: remove(builder, EntityType.timeslots),
        massDeleteTimeslot: massAction(builder, EntityType.timeslots, 'delete'),

        getAdministrator: getOne<Administrator>(builder, EntityType.administrator),
        getAdministrators: getAll<Administrator>(builder, EntityType.administrator),
        listAdministrators: getMany<ListAdministrator>(builder, EntityType.administrator, listAdministratorFields),
        editAdministrators: update<Administrator>(builder, EntityType.administrator),
        createAdministrators: create<Administrator>(builder, EntityType.administrator),
        deleteAdministrators: remove(builder, EntityType.administrator),
        massDeleteAdministrators: massAction(builder, EntityType.administrator, 'delete'),

        getNotifications: getAll<Notification>(builder, EntityType.notifications),
        markAsRead: builder.mutation<void, { id: string }>({
            query: ({ id }) => ({
                url: `/v1/notifications/${id}/-actions/mark-as-read`,
                method: 'POST',
            }),
            invalidatesTags: (_result, _error, { id }) => [
                { type: EntityType.notifications, id: 'LIST' },
                { type: EntityType.notifications, id },
            ],
        }),

        getActivity: getAll<Activity>(builder, EntityType.activity),
    }),
});

export type EndpointNames = keyof typeof api.endpoints;
