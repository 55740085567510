import { useEffect, useMemo, useRef, type ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useEventHandler } from 'hooks/useEventHandler';
import { HistoryContext } from './HistoryContext';

export const HistoryProvider = ({ children }: { children: ReactNode }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const ref = useRef<string[]>([]);

    useEffect(() => {
        ref.current.push(location.pathname);
    }, [location]);

    const goBack = useEventHandler(() => {
        const lastIndex = ref.current.length - 1;

        const index = ref.current.findLastIndex((path) => path !== location.pathname && !/(new|edit)/giu.test(path));

        ref.current.splice(index);

        navigate(index - lastIndex);
    });

    const value = useMemo(
        () => ({
            goBack,
        }),
        [goBack],
    );

    return <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>;
};
