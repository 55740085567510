import { i18n } from '@lingui/core';

import type { Locales } from 'locales';
import { locales } from 'locales';
import type { PreferenceState } from 'store/preferences';
import { isDefined } from 'utils/isDefined';

export const activateLocale = async (locale: Locales) => {
    const { default: backend_messages } = (await import(`../locales/${locale}.json`)) as {
        default: Record<string, string>;
    };

    const { messages: messages } = (await import(`../locales/${locale}.po`)) as {
        messages: Record<string, string>;
    };

    i18n.loadAndActivate({
        locale,
        messages: {
            ...messages,
            ...backend_messages,
        },
    });
};

export const detectLocaleFromLocalStorage = () => {
    // this value is managed by redux but we can't wait for the store to load
    // since react-router runs this file before the react tree
    const preferences = window.localStorage.getItem('persist:preferences');
    return isDefined(preferences)
        ? (JSON.parse((JSON.parse(preferences) as PreferenceState).language) as PreferenceState['language'])
        : 'auto';
};

export const detectLocaleFromBrowser = () =>
    locales.find(
        (locale) => window.navigator.language.startsWith(locale) || window.navigator.languages.includes(locale),
    ) ?? locales[0];

export const detectLocale = () => {
    const preference = detectLocaleFromLocalStorage();
    const detected = detectLocaleFromBrowser();
    return preference === 'auto' ? detected : preference;
};

export const localeLoader = async () => {
    const locale = detectLocale();
    await activateLocale(locale);
    return null;
};
