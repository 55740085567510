import type { ForwardedRef, MutableRefObject } from 'react';

export const mergeRefs =
    <E extends HTMLElement>(...refs: (ForwardedRef<E> | MutableRefObject<E | null>)[]) =>
    (node: E | null) => {
        for (const ref of refs) {
            if (typeof ref === 'function') ref(node);
            else if (ref !== null) ref.current = node;
        }
    };
