import { useEventHandler } from 'hooks/useEventHandler';
import { api } from 'store/api';
import { type Notification } from 'store/notifications';
import { NotificationCard } from './NotificationCard';
import { useSwipe } from './useSwipe';

export const NotificationMessage = ({
    id,
    ...message
}: Notification & Omit<NotificationCard, 'handleDismiss'> & { id: string }) => {
    const [markAsRead] = api.useMarkAsReadMutation();

    const handleDragEnd = useSwipe(() => {
        void markAsRead({ id });
    });

    const handleDismiss = useEventHandler(() => {
        void markAsRead({ id });
    });

    return <NotificationCard {...message} onDragEnd={handleDragEnd} handleDismiss={handleDismiss} />;
};
