import * as Toast from '@radix-ui/react-toast';
import { useCallback } from 'react';

import { notificationSlice, type Notification } from 'store/notifications';
import { useAppDispatch } from 'store/store';
import { NotificationCard } from './NotificationCard';
import { useSwipe } from './useSwipe';

export const ToastMessage = ({
    id,
    title,
    severity,
    important = false,
    ...props
}: Notification & {
    id: string;
}) => {
    const dispatch = useAppDispatch();

    const handleDismiss = useCallback(() => {
        dispatch(notificationSlice.actions.dismissToast(id));
    }, [dispatch, id]);

    const handleDragEnd = useSwipe(handleDismiss);

    return (
        <Toast.Root forceMount open type={important ? 'foreground' : 'background'} onOpenChange={handleDismiss} asChild>
            <NotificationCard
                {...props}
                title={title}
                severity={severity}
                onDragEnd={handleDragEnd}
                handleDismiss={handleDismiss}
                shadow
            />
        </Toast.Root>
    );
};
