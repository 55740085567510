import Handlebars from 'handlebars';
import type { ComponentProps, ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';

import { Link } from 'components/Link/Link';

const components = {
    a: ({ href = '', color, ...props }: ComponentProps<'a'>) => <Link {...props} to={href} />,
} as const;

export const formatToast = (message: string, replacements: Record<string, unknown> = {}): ReactNode => {
    const template = Handlebars.compile(message);

    const markdown = template(replacements);

    return <ReactMarkdown components={components}>{markdown}</ReactMarkdown>;
};
