import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { useEffect, useMemo, type ReactNode } from 'react';
import { I18nProvider as ReactAriaI18nProvider } from 'react-aria';

import { preferencesSlice } from 'store/preferences';
import { useAppSelector } from 'store/store';
import { activateLocale, detectLocaleFromBrowser } from './activateLocale';

export const LanguageManager = ({ children }: { children: ReactNode }) => {
    const languagePreference = useAppSelector(preferencesSlice.selectors.selectLanguagePreference);

    const resolvedLocale = useMemo(() => {
        const detected = detectLocaleFromBrowser();
        return languagePreference === 'auto' ? detected : languagePreference;
    }, [languagePreference]);

    useEffect(() => {
        void activateLocale(resolvedLocale);
        window.document.documentElement.lang = resolvedLocale;
    }, [resolvedLocale]);

    return (
        <ReactAriaI18nProvider locale={resolvedLocale}>
            <I18nProvider i18n={i18n}>{children}</I18nProvider>
        </ReactAriaI18nProvider>
    );
};
