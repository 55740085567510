import {
    endOfMonth,
    endOfWeek,
    endOfYear,
    startOfMonth,
    startOfWeek,
    startOfYear,
    type CalendarDate,
} from '@internationalized/date';
import { useMemo } from 'react';
import { useLocale } from 'react-aria';

import type { TimeSpans } from './_types';

export const timeSpanUtils = (locale: string) => ({
    startOf: (time: TimeSpans, date: CalendarDate) =>
        ({
            day: date,
            week: startOfWeek(date, locale),
            month: startOfMonth(date),
            year: startOfYear(date),
        })[time],
    endOf: (time: TimeSpans, date: CalendarDate) =>
        ({
            day: date,
            week: endOfWeek(date, locale),
            month: endOfMonth(date),
            year: endOfYear(date),
        })[time],
});

export const useTimeSpanUtils = () => {
    const { locale } = useLocale();

    return useMemo(() => timeSpanUtils(locale), [locale]);
};
