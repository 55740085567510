import type { ReactNode } from 'react';
import { useEffect, useRef } from 'react';
import { pageTitle } from './page.css';

export const PageTitle = ({ children }: { children: ReactNode }) => {
    const ref = useRef<HTMLHeadingElement | null>(null);

    useEffect(() => {
        const before = window.document.title;

        const setTitle = () => {
            // eslint-disable-next-line lingui/no-unlocalized-strings
            window.document.title = `Fastis - ${ref.current?.textContent ?? ''}`;
        };

        const observer = new MutationObserver(setTitle);

        observer.observe(ref.current!, { subtree: true, characterData: true });

        setTitle();

        () => {
            observer.disconnect();
            window.document.title = before;
        };
    }, []);

    return (
        <h1 ref={ref} className={pageTitle}>
            {children}
        </h1>
    );
};
