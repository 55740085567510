import { useLingui } from '@lingui/react';
import { useMemo } from 'react';
import { useDateTimeFormatter } from './useDateTimeFormatter';

type DateOrStringOrTuple = Date | string | readonly [Date, Date] | readonly [string, string];

interface FormattedDateTimeProps extends Intl.DateTimeFormatOptions {
    value: DateOrStringOrTuple;
}

const isTuple = <Value,>(value: Value | readonly [Value, Value]): value is readonly [Value, Value] =>
    Array.isArray(value) && value.length === 2;

const makeDate = (value: DateOrStringOrTuple): Date | readonly [Date, Date] => {
    if (isTuple(value)) return value.map(makeDate) as unknown as readonly [Date, Date];

    if (typeof value === 'string') {
        return new Date(value);
    }

    return value;
};

export const FormattedDateTime = ({ value, ...options }: FormattedDateTimeProps) => {
    const {
        i18n: { locale },
    } = useLingui();

    const formatter = useDateTimeFormatter(locale, options);

    const val = useMemo(() => makeDate(value), [value]);

    if (isTuple(val)) {
        return formatter.formatRange(...val);
    }

    return formatter.format(val);
};
