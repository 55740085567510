import { useMe } from 'permission/useMe';
import { useCallback } from 'react';
import type { Ability, ProfileID, Role } from 'types';
import { isDefined } from 'utils/isDefined';
import type { RequireOne } from 'utils/RequireOne';

export type PermissionOptions = RequireOne<{
    ability: Ability | Ability[] | undefined;
    role: Role | Role[] | undefined;
    profile: ProfileID | ProfileID[] | undefined;
}>;

export const useHasPermission = () => {
    const { checkPermission, checkRole, checkProfile } = useMe();

    return useCallback(
        ({ ability, role, profile }: PermissionOptions) =>
            (isDefined(ability) && checkPermission(ability)) ||
            (isDefined(role) && checkRole(role)) ||
            (isDefined(profile) && checkProfile(profile)),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
};
