import { plural, select } from '@lingui/macro';

import { type EntityTypes } from 'types';

export const getEntityName = (entityType: EntityTypes) =>
    select(entityType, {
        associations: 'association',
        absenceTypes: 'absence-type',
        absenceMutation: 'absence-mutation',
        absences: 'absences',
        declarations: 'declaration',
        invoices: 'invoice',
        invoiceCollections: 'invoice collection',
        locations: 'location',
        pricings: 'pricing',
        professions: 'profession',
        profiles: 'profile',
        schedules: 'schedule',
        scheduleSlots: 'schedule slot',
        scheduleSteps: 'schedule step',
        scheduleResults: 'schedule result',
        shiftOptions: 'offer',
        settings: 'setting',
        shifts: 'shift',
        skills: 'skill',
        templates: 'shift-type',
        tenants: 'tenant',
        timeslots: 'timeslot',
        users: 'user',
        other: 'record',
    });

export const getEntityNamePlural = (entityType: EntityTypes, count: number) =>
    select(entityType, {
        associations: plural(count, {
            one: 'one association',
            other: '# associations',
        }),
        absences: plural(count, {
            one: 'one absence',
            other: '# absences',
        }),
        declarations: plural(count, {
            one: 'one declaration',
            other: '# declarations',
        }),
        invoices: plural(count, {
            one: 'one invoice',
            other: '# invoices',
        }),
        invoiceCollections: plural(count, {
            one: 'one invoice collection',
            other: '# invoice collection',
        }),
        locations: plural(count, {
            one: 'one location',
            other: '# locations',
        }),
        pricings: plural(count, {
            one: 'one pricing',
            other: '# pricings',
        }),
        professions: plural(count, {
            one: 'one profession',
            other: '# professions',
        }),
        profiles: plural(count, {
            one: 'one profile',
            other: '# profiles',
        }),
        schedules: plural(count, {
            one: 'one schedule',
            other: '# schedules',
        }),
        scheduleSlots: plural(count, {
            one: 'one schedule slot',
            other: '# schedule slots',
        }),
        scheduleSteps: plural(count, {
            one: 'one schedule step',
            other: '# schedule steps',
        }),
        scheduleResults: plural(count, {
            one: 'one schedule result',
            other: '# schedule results',
        }),
        settings: plural(count, {
            one: 'one setting',
            other: '# settings',
        }),
        shifts: plural(count, {
            one: 'one shift',
            other: '# shifts',
        }),
        skills: plural(count, {
            one: 'one skill',
            other: '# skills',
        }),
        shiftOptions: plural(count, {
            one: 'one offer',
            other: '# offers',
        }),
        templates: plural(count, {
            one: 'one shift-type',
            other: '# shift-types',
        }),
        tenants: plural(count, {
            one: 'one tenant',
            other: '# tenants',
        }),
        timeslots: plural(count, {
            one: 'one timeslot',
            other: '# timeslots',
        }),
        users: plural(count, {
            one: 'one user',
            other: '# users',
        }),
        other: 'unknown',
    });

export const getEntityTagPlural = (entityType: EntityTypes, count: number) =>
    select(entityType, {
        associations: plural(count, {
            one: 'association',
            other: 'All associations',
        }),
        absences: plural(count, {
            one: 'absence',
            other: 'All absences',
        }),
        declarations: plural(count, {
            one: 'declaration',
            other: 'All declarations',
        }),
        invoices: plural(count, {
            one: 'invoice',
            other: 'All invoices',
        }),
        invoiceCollections: plural(count, {
            one: 'invoice collection',
            other: 'All invoice collections',
        }),
        locations: plural(count, {
            one: 'location',
            other: 'All locations',
        }),
        professions: plural(count, {
            one: 'profession',
            other: 'All professions',
        }),
        profiles: plural(count, {
            one: 'profile',
            other: 'All profiles',
        }),
        pricings: plural(count, {
            one: 'pricing',
            other: 'All pricings',
        }),
        schedules: plural(count, {
            one: 'schedule',
            other: 'All schedules',
        }),
        scheduleSlots: plural(count, {
            one: 'schedule slot',
            other: 'All schedule slots',
        }),
        scheduleSteps: plural(count, {
            one: 'schedule step',
            other: 'All schedule steps',
        }),
        scheduleResults: plural(count, {
            one: 'schedule result',
            other: 'All schedule results',
        }),
        settings: plural(count, {
            one: 'setting',
            other: 'All settings',
        }),
        shifts: plural(count, {
            one: 'shift',
            other: 'All shifts',
        }),
        skills: plural(count, {
            one: 'skill',
            other: 'All skills',
        }),
        templates: plural(count, {
            one: 'shift-type',
            other: 'All shift-types',
        }),
        tenants: plural(count, {
            one: 'tenant',
            other: 'All tenants',
        }),
        timeslots: plural(count, {
            one: 'timeslot',
            other: 'All timeslots',
        }),
        other: 'unknown',
    });
