import { createSlice } from '@reduxjs/toolkit';

import type { UUID } from 'types';
import { api } from './api';
import { persistSlice } from './persistSlice';

export const accessSlice = persistSlice(
    createSlice({
        name: 'access',
        initialState: {
            accessId: null as 'DEFAULT' | UUID | null,
        },
        reducers: (create) => ({
            selectAccess: create.reducer<UUID | null>((state, { payload }) => {
                state.accessId = payload;
            }),
        }),
        extraReducers: (builder) => {
            builder.addMatcher(api.endpoints.getMe.matchFulfilled, (state, { payload }) => {
                const ids = Object.entries(payload.access).map(([id]) => id as UUID);
                const accessId = payload.context?.id ?? (ids[0] as UUID);

                if (state.accessId === null || !ids.includes(state.accessId)) {
                    state.accessId = accessId;
                }
            });
        },
        selectors: {
            selectAccess: (state) => state.accessId,
        },
    }),
);
