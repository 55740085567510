/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import clsx from 'clsx';
import { m } from 'framer-motion';
import { useBoolean } from 'hooks/useBoolean';
import { useDevice } from 'hooks/useDevice';
import { useCallback } from 'react';
import { sidebarSlice } from 'store/sidebar';
import { useAppSelector } from 'store/store';
import { enhance } from 'utils/enhance';
import { mergeRefs } from 'utils/mergeRefs';
import { pageContainer, type PageContainerVariants } from './page.css';

export const PageContainer = enhance<'div', PageContainerVariants>(
    'PageContainer',
    ({ children, className, list = false }, ref) => {
        const sidebarOpen = useAppSelector(sidebarSlice.selectors.isOpen);
        const [sidebarPresent, setSidebarPresent] = useBoolean(false);
        const { mobile } = useDevice();

        const initial = {
            paddingLeft: sidebarOpen && list ? 400 : 64,
        };

        const mobilePadding = mobile ? 18 : 64;

        const drawerAnimation = {
            paddingLeft: sidebarOpen && sidebarPresent && !mobile ? 400 : mobilePadding,
        };

        const getSidebarPresence = useCallback(
            (node: HTMLDivElement | null) => {
                node && node.firstElementChild?.nextElementSibling?.firstElementChild?.id === 'pageSidebar'
                    ? setSidebarPresent.on()
                    : setSidebarPresent.off();
            },
            [setSidebarPresent],
        );

        return (
            <m.div
                initial={initial}
                animate={drawerAnimation}
                ref={mergeRefs(ref, getSidebarPresence)}
                className={clsx(pageContainer({ list }), className)}
            >
                {children}
            </m.div>
        );
    },
);
