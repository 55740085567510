import type { SelectOption } from 'components/Select/SelectOptions';
import { createContext } from 'react';
import type { Ability, ProfileID, Role, TempUserData, TempUserDataAccess } from 'types';
import type { Prettify } from 'utils/prettify';

type PermissionContextValue = {
    user: TempUserData;
    accessOptions: SelectOption[];
    activeAccess: Prettify<TempUserDataAccess[number]>;
    accountsList: Prettify<TempUserDataAccess>;
    multipleTenantAccessList: Prettify<TempUserDataAccess>;
    singularTenantAccessList: Prettify<TempUserDataAccess>;
    checkPermission: (ability: Ability | Ability[]) => boolean;
    checkRole: (role: Role | Role[]) => boolean;
    checkProfile: (profileId: ProfileID | ProfileID[]) => boolean;
};

export const PermissionContext = createContext<PermissionContextValue | undefined>(undefined);
