import { optionsOptions } from 'hooks/createOptionsHook';
import { redirect, type LoaderFunctionArgs, type Params } from 'react-router-dom';
import { api, type EndpointNames } from './api';
import { DEFAULT_PAGINATION_REQUEST } from './config';
import { stopNotifications, store } from './store';
import type { AnyApiEndpointQuery } from './types';

const DEFAULT_ARGS = { search: {} } as Record<string, unknown>;

export const detailLoader =
    <EndpointName extends `${'get' | 'list'}${string}` & EndpointNames>(endpointName: EndpointName) =>
    async (args: LoaderFunctionArgs) => {
        const endpoint = api.endpoints[endpointName] as AnyApiEndpointQuery;
        const thunk = endpoint.initiate(args.params);
        const promise = store.dispatch(thunk);
        await promise.unwrap();
        promise.unsubscribe();
        return null;
    };

export const mergeParams = <P extends Params>(
    params: P,
    search: URLSearchParams,
    { search: searchArgs, ...rest } = DEFAULT_ARGS,
) => ({
    path: params,
    search: { ...DEFAULT_PAGINATION_REQUEST, ...Object.fromEntries(search), ...(searchArgs as Record<string, string>) },
    ...rest,
});

export const mergeOnlyParams = <P extends Params>(params: P, search: URLSearchParams) => ({
    path: params,
    search: { ...Object.fromEntries(search) },
});

export const paginationLoader =
    <EndpointName extends `${'get' | 'list'}${string}` & EndpointNames>(
        endpointName: EndpointName,
        searchArgs: Record<string, unknown> = {},
    ) =>
    async (args: LoaderFunctionArgs) => {
        const endpoint = api.endpoints[endpointName] as AnyApiEndpointQuery;
        const thunk = endpoint.initiate(
            mergeParams(args.params, new window.URL(args.request.url).searchParams, searchArgs),
        );
        const promise = store.dispatch(thunk);
        await promise.unwrap();
        promise.unsubscribe();
        return null;
    };

export const allLoader =
    <EndpointName extends `${'get' | 'list'}${string}` & EndpointNames>(endpointName: EndpointName) =>
    async (args: LoaderFunctionArgs) => {
        const endpoint = api.endpoints[endpointName] as AnyApiEndpointQuery;

        const thunk = endpoint.initiate(
            mergeOnlyParams(args.params, new window.URL(args.request.url).searchParams).search,
        );
        const promise = store.dispatch(thunk);
        await promise.unwrap();
        promise.unsubscribe();
        return null;
    };

export const optionsLoader = async <E extends `get${string}s` & EndpointNames>(endpointName: E) => {
    const endpoint = api.endpoints[endpointName] as AnyApiEndpointQuery;
    const thunk = endpoint.initiate(optionsOptions);
    const promise = store.dispatch(thunk);
    await promise.unwrap();
    promise.unsubscribe();
};

export const userLoader = async (args: LoaderFunctionArgs) => {
    const user = await store.dispatch(api.endpoints.getMe.initiate());

    if (user.isError && !args.request.url.includes('login')) return redirect('/logout');

    return null;
};

export const logoutLoader = () => {
    store.dispatch(api.util.resetApiState());
    localStorage.removeItem('persist:access');

    return null;
};

export const resetAccess = () => {
    store.dispatch(api.util.resetApiState());
    store.dispatch(stopNotifications());
    localStorage.removeItem('persist:access');
};
