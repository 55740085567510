import clsx from 'clsx';
import { m, type MotionProps } from 'framer-motion';
import type { ComponentProps, ReactNode } from 'react';
import { enhance } from 'utils/enhance';
import { card, type CardVariants } from './Card.css';

export const Card = enhance<'div', CardVariants>(
    'Card',
    (
        {
            variant,
            className,
            children,
            edge,
            edgeColor,
            color,
            height,
            shadow,
            size,
            borderStyle,
            width,
            active,
            ...props
        },
        ref,
    ) => (
        <div
            {...props}
            ref={ref}
            className={clsx(
                card({ variant, edge, edgeColor, color, shadow, height, width, size, borderStyle, active }),
                className,
            )}
        >
            {children}
        </div>
    ),
);

export const MotionCard = m(Card) as (
    props: Omit<ComponentProps<typeof Card>, keyof MotionProps> & MotionProps,
) => ReactNode;
