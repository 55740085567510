import clsx from 'clsx';

import { enhance } from 'utils/enhance';
import { pageContent } from './page.css';

export const PageContent = enhance<'div'>('pageContent', ({ children, className, ...props }, ref) => {
    return (
        <div ref={ref} className={clsx(pageContent, className)} {...props}>
            {children}
        </div>
    );
});
