import type { Prettify } from 'utils/prettify';
import type { UserAccessPermissions, UserAccessRoles } from '../../storage/app/api/enums';
import type {
    Absence,
    AbsenceMutation,
    AbsenceType,
    Address,
    Declaration,
    Invoice,
    InvoiceCollection,
    Location as OriginalLocation,
    Pricing,
    Profession,
    Profile,
    Schedule,
    ScheduleOption,
    ScheduleShift,
    ScheduleSlot,
    ScheduleStep,
    Shift,
    Skill,
    Template,
    Tenant,
    Timeslot,
    UploadType,
    User,
    UUID,
} from '../../storage/app/api/schema_v1';

export * from '../../storage/app/api/enums';
export type * from '../../storage/app/api/schema_v1';

export const listAbsenceMutationFields = [
    'profile_id',
    'created_at',
    'absence_type_id',
    'absence_id',
    'mutation',
    'mutation_type',
    'is_reset',
    'expires_at',
    'profile_name',
    'profile_avatar',
    'profile_type',
    'profile_profession_name',
] as const satisfies ReadonlyArray<keyof AbsenceMutation>;

export type ListAbsenceMutation = Prettify<
    { id: string } & Pick<AbsenceMutation, (typeof listAbsenceMutationFields)[number]>
>;

export const listAbsenceFields = [
    'profile_id',
    'starts_at',
    'ends_at',
    'description',
    'status',
    'reason',
    'notes_private',
    'notes_public',
    'sorting',
    'created_at',
    'profile_name',
    'profile_avatar',
    'profile_type',
    'profile_profession_name',
    'created_at',
    'updated_at',
] as const satisfies ReadonlyArray<keyof Absence>;

export type ListAbsence = Prettify<{ id: string } & Pick<Absence, (typeof listAbsenceFields)[number]>>;

export const listAbsenceTypeFields = [
    'name',
    'priority',
    'is_default',
    'expiration_mechanism',
    'expiration_value',
    'expiration_interval',
    'expiration_mechanism',
] as const satisfies ReadonlyArray<keyof AbsenceType>;

export type ListAbsenceType = Prettify<{ id: string } & Pick<AbsenceType, (typeof listAbsenceTypeFields)[number]>>;

export const listInvoiceFields = [
    'identifier',
    'invoice_collection_id',
    'invoice_collection_name',
    'invoice_date',
    'is_collectable',
    'locked_at',
    'name',
    'profile_avatar',
    'profile_id',
    'profile_name',
    'profile_profession_code',
    'profile_profession_name',
    'profile_type',
    'total_excl_vat',
    'total_incl_vat',
    'updated_at',
    'is_collectable',
    'created_at',
] as const satisfies ReadonlyArray<keyof Invoice>;

export type ListInvoice = Prettify<{ id: string } & Pick<Invoice, (typeof listInvoiceFields)[number]>>;

export const listDetailInvoiceFields = ['id', 'name', 'date', 'total'] as const satisfies ReadonlyArray<
    keyof InvoiceCollection['negative_balances'][number]['invoices'][number]
>;

export type ListDetailInvoice = Prettify<
    { id: string } & Pick<
        InvoiceCollection['negative_balances'][number]['invoices'][number],
        (typeof listDetailInvoiceFields)[number]
    >
>;

export const listDeclarationFields = [
    'shift_date',
    'shift_id',
    'shift_from',
    'shift_till',
    'declared_costs_count',
    'description',
] as const satisfies ReadonlyArray<keyof Declaration>;

export type ListDeclarations = Prettify<{ id: string } & Pick<Declaration, (typeof listDeclarationFields)[number]>>;

export const GetInvoiceCollectionFields = [
    'identifier',
    'invoice_count',
    'locked_at',
    'name',
    'negative_balances',
    'profile_count',
    'total_excl_vat',
    'total_incl_vat',
    'total_vat',
    'collect_from',
    'collect_till',
    'missing_ibans',
    'missing_ibans_count',
] as const satisfies ReadonlyArray<keyof InvoiceCollection>;

export type GetInvoiceCollection = Prettify<
    { id: string } & Pick<InvoiceCollection, (typeof GetInvoiceCollectionFields)[number]>
>;

export const listInvoiceCollectionFields = [
    'collect_from',
    'collect_till',
    'name',
    'total_excl_vat',
    'total_incl_vat',
    'locked_at',
    'invoice_count',
    'negative_balances_count',
    'created_at',
] as const satisfies ReadonlyArray<keyof InvoiceCollection>;

export type ListInvoiceCollection = Prettify<
    { id: string } & Pick<InvoiceCollection, (typeof listInvoiceCollectionFields)[number]>
>;

export const listMissingIbansFields = ['profile_id', 'profile_name'] as const satisfies ReadonlyArray<
    keyof InvoiceCollection['missing_ibans'][number]
>;

export type ListMissingIbans = Prettify<
    { id: string } & Pick<InvoiceCollection['missing_ibans'][number], (typeof listMissingIbansFields)[number]>
>;

export type Location = Omit<OriginalLocation, 'address_default'> & { address_default: Address };

export const listUploadTypeFields = ['name', 'created_at', 'updated_at'] as const satisfies ReadonlyArray<
    keyof UploadType
>;

export type ListUploadTypes = Prettify<{ id: string } & Pick<UploadType, (typeof listUploadTypeFields)[number]>>;

export const listLocationFields = [
    'name',
    'code_name',
    'code',
    'created_at',
    'updated_at',
] as const satisfies ReadonlyArray<keyof Location>;

export type ListLocation = Prettify<{ id: string } & Pick<Location, (typeof listLocationFields)[number]>>;

export const listProfessionFields = [
    'name',
    'code_name',
    'code',
    'created_at',
    'updated_at',
] as const satisfies ReadonlyArray<keyof Profession>;

export type ListProfession = Prettify<{ id: string } & Pick<Profession, (typeof listProfessionFields)[number]>>;

export const listProfileFields = [
    'avatar',
    'active_from',
    'active_till',
    'business_profile_name',
    'business_profile_id',
    'group_profile_name',
    'group_profile_id',
    'is_individual',
    'identifier_vat',
    'identifier_company',
    'created_at',
    'email',
    'location_codes',
    'location_ids',
    'location_names',
    'name',
    'phone_1',
    'profession_code',
    'profession_id',
    'profession_name',
    'profile_type',
    'skill_codes',
    'skill_ids',
    'skill_names',
    'timeslot_codes',
    'timeslot_ids',
    'timeslot_names',
    'updated_at',
] as const satisfies ReadonlyArray<keyof Profile>;

export type ListProfile = Prettify<{ id: string } & Pick<Profile, (typeof listProfileFields)[number]>>;

export const listScheduleSlotFields = [
    'name',
    'shift_date',
    'created_at',
    'updated_at',
] as const satisfies ReadonlyArray<keyof ScheduleSlot>;

export type ListScheduleSlot = Prettify<{ id: string } & Pick<ScheduleSlot, (typeof listScheduleSlotFields)[number]>>;

export const listScheduleShiftsFields = [
    'name',
    'shift_date',
    'created_at',
    'updated_at',
    'profile_id',
    'location_code',
    'location_name',
    'profession_codes',
    'profession_names',
    'timeslot_name',
    'timeslot_code',
    'template_id',
    'skill_codes',
    'skill_names',
    'shift_date',
    'shift_from',
    'shift_till',
    'code',
    'profile_avatar',
    'profile_type',
    'profile_name',
    'options_data',
    'profile_profession_name',
    'sorting',
    'location_id',
] as const satisfies ReadonlyArray<keyof ScheduleShift>;

export type ListScheduleShifts = Prettify<
    { id: string; current_schedule_options: ScheduleOption[] } & Pick<
        ScheduleShift,
        (typeof listScheduleShiftsFields)[number]
    >
>;

export const listScheduleShiftsPlanFields = [
    'name',
    'shift_date',
    'created_at',
    'updated_at',
    'profile_id',
    'location_code',
    'location_name',
    'profession_codes',
    'profession_names',
    'timeslot_name',
    'timeslot_code',
    'template_id',
    'skill_codes',
    'skill_names',
    'shift_date',
    'shift_from',
    'shift_till',
    'code',
    'profile_avatar',
    'profile_type',
    'profile_name',
    'profile_profession_name',
] as const satisfies ReadonlyArray<keyof ScheduleShift>;

export type ListScheduleShiftsPlan = Prettify<
    { id: string } & Pick<ScheduleShift, (typeof listScheduleShiftsPlanFields)[number]>
>;

export type ListScheduleShiftsCard = Prettify<
    { id: string } & Pick<ScheduleShift, (typeof listScheduleShiftsPlanFields)[number]>
>;

export const listScheduleFields = [
    'name',
    'schedule_steps_current',
    'schedule_steps_count',
    'schedule_from',
    'schedule_till',
    'completed_at',
    'status',
] as const satisfies ReadonlyArray<keyof Schedule>;

export type ListSchedule = Prettify<{ id: string } & Pick<Schedule, (typeof listScheduleFields)[number]>>;

export const listScheduleStepsFields = [
    'name',
    'status',
    'step_type',
    'starts_at',
    'ends_at',
    'scheduling_settings',
    'schedule_id',
] as const satisfies ReadonlyArray<keyof ScheduleStep>;

export type ListScheduleStep = Prettify<{ id: string } & Pick<ScheduleStep, (typeof listScheduleStepsFields)[number]>>;

export const listShiftFields = [
    'assignee_is_present',
    'invoiced_at',
    'code',
    'created_at',
    'extra_profiles_data',
    'extra_profile_ids',
    'profile_name',
    'profile_id',
    'profile_avatar',
    'profile_type',
    'profile_profession_name',
    'profile_profession_code',
    'notes_private',
    'notes_public',
    'identifier',
    'location_code',
    'location_name',
    'name',
    'profession_codes',
    'profession_names',
    'shift_date',
    'shift_from',
    'shift_till',
    'skill_codes',
    'skill_names',
    'sorting',
    'timeslot_codes',
    'timeslot_names',
    'timeslot_ids',
    'parent_id',
    'market_type',
] as const satisfies ReadonlyArray<keyof Shift>;

export type ListShift = Prettify<{ id: string } & Pick<Shift, (typeof listShiftFields)[number]>>;

export const shiftDetailFields = [
    'requires_all_skills',
    'name',
    'code',
    'profile_data',
    'extra_profiles_data',
    'related_profiles_data',
    'location_id',
    'location_name',
    'location_code',
    'profession_names',
    'profession_codes',
    'profile_id',
    'timeslot_names',
    'timeslot_codes',
    'skill_names',
    'identifier',
    'notes_private',
    'notes_public',
    'assignee_is_present',
    'market_type',
    'shift_from',
    'shift_till',
    'invoiced_at',
    'skill_codes',
    'shift_date',
    'shift_days',
    'tenant_id',
    'requires_all_timeslots',
] as const satisfies ReadonlyArray<keyof Shift>;

export type DetailShift = Prettify<{ id: string } & Pick<Shift, (typeof shiftDetailFields)[number]>>;

export const listSkillFields = [
    'name',
    'code_name',
    'code',
    'created_at',
    'updated_at',
] as const satisfies ReadonlyArray<keyof Skill>;

export type ListSkill = Prettify<{ id: string } & Pick<Skill, (typeof listSkillFields)[number]>>;

export const listTemplateFields = [
    'name',
    'code',
    'active_from',
    'active_till',
    'shift_from',
    'shift_till',
    'scheduling_index',
    'scheduling_automatic_allowed',
    'scheduling_options_allowed',
    'created_at',
    'updated_at',
    'profession_names',
    'skill_names',
    'skill_codes',
    'profession_codes',
    'timeslot_names',
    'timeslot_codes',
    'shift_hours',
] as const satisfies ReadonlyArray<keyof Template>;

export type ListTemplate = Prettify<{ id: string } & Pick<Template, (typeof listTemplateFields)[number]>>;

export const listTenantFields = ['name', 'created_at', 'updated_at'] as const satisfies ReadonlyArray<keyof Tenant>;

export type ListTenant = Prettify<{ id: string } & Pick<Tenant, (typeof listTenantFields)[number]>>;

export const listTimeslotFields = [
    'name',
    'code',
    'code_name',
    'scheduling_age_min',
    'scheduling_age_max',
    'created_at',
    'updated_at',
    'shift_from',
    'shift_till',
    'sorting',
] as const satisfies ReadonlyArray<keyof Timeslot>;

export type ListTimeslot = Prettify<{ id: string } & Pick<Timeslot, (typeof listTimeslotFields)[number]>>;

export const listAdministratorFields = [
    'name',
    'email',
    'created_at',
    'updated_at',
    'access_role',
    'access_permissions',
] as const satisfies ReadonlyArray<keyof User>;

export type ListAdministrator = Prettify<{ id: string } & Pick<User, (typeof listAdministratorFields)[number]>>;

export const listPricingFields = [
    'name',
    'code_name',
    'code',
    'created_at',
    'updated_at',
] as const satisfies ReadonlyArray<keyof Pricing>;

export type ListPricing = Prettify<{ id: string } & Pick<Pricing, (typeof listPricingFields)[number]>>;

export const EntityType = {
    absences: 'absences',
    administrator: 'administrators',
    absenceMutation: 'absence-mutations',
    absenceTypes: 'absence-types',
    declarations: 'declarations',
    invoiceCollections: 'invoice-collections',
    invoices: 'invoices',
    locations: 'locations',
    professions: 'professions',
    profiles: 'profiles',
    pricings: 'pricing',
    schedules: 'schedules',
    scheduleShifts: 'schedule-shifts',
    scheduleSlots: 'schedule-slots',
    scheduleSteps: 'schedule-steps',
    scheduleResults: 'schedule-results',
    shifts: 'shifts',
    shiftOptions: 'shift-options',
    skills: 'skills',
    templates: 'templates',
    tenants: 'tenants',
    timeslots: 'timeslots',
    users: 'users',
    uploadTypes: 'upload-types',
    notifications: 'notifications',
    activity: 'activity',
} as const;

export const SingularEntityType = {
    absences: 'absence',
    administrator: 'administrator',
    scheduleShifts: 'schedule-shift',
    absenceMutation: 'absence-mutation',
    absenceTypes: 'absence-type',
    declarations: 'declaration',
    invoiceCollections: 'invoice-collection',
    invoices: 'invoice',
    locations: 'location',
    professions: 'profession',
    profiles: 'profile',
    pricings: 'pricing',
    schedules: 'schedule',
    scheduleSlots: 'schedule-slot',
    scheduleSteps: 'schedule-step',
    scheduleResults: 'schedule-result',
    shifts: 'shift',
    shiftOptions: 'shift-option',
    skills: 'skill',
    templates: 'template',
    tenants: 'tenant',
    timeslots: 'timeslot',
    users: 'user',
    uploadTypes: 'upload-type',
    notifications: 'notification',
    activity: 'activity',
} as const;

export type EntityTypes = keyof typeof EntityType;
export type EntityTypeStrings = (typeof SingularEntityType)[EntityTypes];

export type Ability = `${'!' | ''}${EntityTypeStrings | '*'}.${string}`;

export type Role = UserAccessRoles | `!${UserAccessRoles}`;

export type ProfileID = UUID | `!${UUID}`;

export interface Credentials {
    email: string;
    password: string;
    remember: boolean;
}

export interface LoginResponse extends TempUserData {
    token: string;
}

export interface ChangePasswordPayload {
    current_password: string;
    password: string;
    password_confirmation: string;
}

export interface ResetPasswordPayload {
    password: string;
    password_confirmation: string;
    token: string;
    email: string;
}

export const weekCardFields = [
    'assignee_is_present',
    'code',
    'extra_profile_ids',
    'invoiced_at',
    'location_id',
    'notes_private',
    'notes_public',
    'parent_id',
    'profile_id',
    'profile_name',
    'shift_date',
    'shift_from',
    'shift_till',
] as const satisfies ReadonlyArray<keyof Shift>;

export type WeekCardShift = Pick<Shift, (typeof weekCardFields)[number] | 'id'>;

export type TempUserData = {
    context:
        | {
              id: UUID;
              item_id: UUID;
              item_type: string;
              item_name: string;
              role: UserAccessRoles;
              permissions: UserAccessPermissions[];
              abilities: Record<string, unknown>;
              association_tenant_id: UUID;
              association_tenant_name: string;
              association_item_type: string;
              association_item_id: UUID;
              access_multiple_tenants: boolean;
              access_singular_tenant: boolean;
          }
        | undefined;
    id: UUID;
    name: string;
    email: string;
    avatar: string;
    oauth_driver: string;
    two_factor: boolean;
    access: {
        id: UUID;
        item_id: UUID;
        item_type: string;
        item_name: string;
        role: UserAccessRoles;
        permissions: UserAccessPermissions[];
        abilities: Record<string, unknown>;
        association_tenant_id: UUID;
        association_tenant_name: string;
        association_item_type: string;
        association_item_id: UUID;
        access_multiple_tenants: boolean;
        access_singular_tenant: boolean;
    }[];
};

export type TempUserDataAccess = TempUserData['access'];

export type Invitation = {
    id: string;
    user_id: null;
    active_till: string;
    active_from: string;
    associated_type: string;
    associated_id: UUID;
    tenant_id: UUID;
    tenant_name: string;
    item_id: UUID;
    access_role: UserAccessRoles;
    permissions: UserAccessPermissions[];
    email: string;
    name: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
};

export type InvitationCard = {
    tenant_name: string;
    name: string;
    email: string;
    role: Exclude<UserAccessRoles, 'ADMINISTRATOR_OWNER' | 'ADMINISTRATOR_DEFAULT'>;
};
