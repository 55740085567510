import type { I18n } from '@lingui/core';
import { useLingui } from '@lingui/react';
import { useCallback } from 'react';
import { isFirstToUpper } from 'utils/isUpperCase';
import { replaceWordsInTrans, type TranslateOptions } from 'utils/replaceWordsInTrans';
import { toFirstUpperCase } from 'utils/toFirstUpper';
import { toHashMap } from 'utils/toHashMap';

type TranslateProps = {
    _: I18n['_'];
    id: string;
    messages?: string[];
    toFirstUpper: boolean;
    values: Record<string, string>;
};

const translationToLower = (id: string) => {
    const parts = id.split('.');
    // eslint-disable-next-line unicorn/prefer-at
    const lastPart = parts[parts.length - 1];
    const msgToLower = [...parts.slice(0, -1), lastPart?.toLowerCase()].join('.');

    const msgId = parts.length > 1 ? msgToLower : id;
    const toFirstUpper = lastPart != null && isFirstToUpper(lastPart);

    return {
        msgId,
        toFirstUpper,
    };
};

const translate = ({ _, id, messages, toFirstUpper, values }: TranslateProps) => {
    const isTranslated = (msg: string) => _(msg, values) !== id;
    const message = messages?.find(isTranslated) ?? id;
    const translation = replaceWordsInTrans({ id: _(message, values), values });

    if (toFirstUpper) return toFirstUpperCase(translation);

    return translation;
};

const translateRecord = (_: I18n['_'], values: TranslateOptions['values']) => {
    if (!values) return {};

    return toHashMap(
        (key) => key,
        (key) => {
            const { msgId: id, toFirstUpper } = translationToLower(values[key] ?? '');

            return translate({ id, values, toFirstUpper, _ });
        },
        Object.keys(values),
    );
};

export const useTranslate = () => {
    const { _ } = useLingui();

    return useCallback(
        ({ id, values: msgIds }: TranslateOptions) => {
            const { msgId, toFirstUpper } = translationToLower(id);
            const values = translateRecord(_, msgIds);
            const messages = [id, msgId];

            return translate({ _, id, messages, toFirstUpper, values });
        },
        [_],
    );
};
